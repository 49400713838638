import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import TicketSteps from "../../elements/tickets/ticket-steps";
import Intro from "../../elements/blocks/intro";
import TicketInformationForm from "../../elements/tickets/ticket-information-form";
import ContactHistoryViewModal from "../../elements/contacts/contact-history-view-modal";
import Nope from "../../elements/blocks/nope";
import { useApiTenant } from "../../../api/api-tenant";
export default function TicketInformation() {
    const { t } = useTranslation();
    const { id } = useParams();
    const api = useApiTenant();
    const [isLoading, setIsLoading] = useState(true);
    const [ticket, setTicket] = useState(null);
    const [historyToDelete, setHistoryToDelete] = useState("");
    const [openContactHistory, setOpenContactHistory] = useState(false);
    useEffect(() => {
        api.GET_ticket(id)
            .then(response => {
            setTicket(response.data.ticket);
            setIsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsLoading(false);
            }
        });
        return () => api.cancel();
    }, [historyToDelete]);
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("tickets.ticket") + " #" + id, breadcrumbs: [
                    {
                        name: t("campaigns.campaigns"),
                        link: "/campaigns",
                    },
                    {
                        name: ticket ? ticket.campaign.name : t("campaigns.campaign"),
                        link: "/campaigns/" + (ticket ? ticket.campaign.id : ""),
                    },
                    {
                        name: t("tickets.ticket") + " #" + id,
                        link: "/tickets/" + id,
                    },
                ], button: ticket && ticket.is_draft
                    ? {
                        label: t("tickets.view-contact"),
                        action: () => setOpenContactHistory(true),
                        color: "outlined",
                        type: "edit",
                    }
                    : undefined, loading: isLoading }), isLoading ? (_jsx(Fragment, {})) : ticket ? (_jsxs(Fragment, { children: [_jsx(TicketSteps, { step: "information", campaign: ticket.campaign, ticket: ticket }), _jsx(TicketInformationForm, { ticket: ticket, historyToDelete: historyToDelete, setHistoryToDelete: setHistoryToDelete }), _jsx(ContactHistoryViewModal, { showContactModal: openContactHistory, setShowContactModal: setOpenContactHistory, campaign: ticket.campaign, contact: ticket.contact })] })) : (_jsx(Nope, { title: t("errors.404"), caption: t("tickets.ticket-not-found") }))] }));
}
