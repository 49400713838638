import LogIn from "./components/pages/auth/login";
import ForgotPassword from "./components/pages/auth/forgot-password";
import ResetPassword from "./components/pages/auth/reset-password";
import SurveyTicket from "./components/pages/campaigns/survey-ticket";
import ActivityTicket from "./components/pages/campaigns/activity-ticket";
import Home from "./components/pages/home";
import ThreeCX from "./components/pages/tickets/ticket-3cx";
import Administration from "./components/pages/administration/administration";
import Clients from "./components/pages/administration/clients/clients";
import ClientNew from "./components/pages/administration/clients/client-new";
import ClientView from "./components/pages/administration/clients/client-view";
import ClientEdit from "./components/pages/administration/clients/client-edit";
import CentralUsers from "./components/pages/administration/users/central-users";
import CentralUserNew from "./components/pages/administration/users/central-user-new";
import CentralUserView from "./components/pages/administration/users/central-user-view";
import CentralUserEdit from "./components/pages/administration/users/central-user-edit";
import CentralGroups from "./components/pages/administration/groups/central-groups";
import CentralLogs from "./components/pages/administration/logs/central-logs";
import CentralBlacklist from "./components/pages/administration/blacklist/blacklist-view";
import Account from "./components/pages/account/account";
import AccountEdit from "./components/pages/account/account-edit";
import Dashboard from "./components/pages/dashboard";
import UserNotifications from "./components/pages/notifications/user-notifications";
import TenantNotifications from "./components/pages/settings/notifications/tenant-notifications";
import Campaigns from "./components/pages/campaigns/campaigns";
import CampaignNew from "./components/pages/campaigns/campaign-new";
import CampaignView from "./components/pages/campaigns/campaign-view";
import CampaignEdit from "./components/pages/campaigns/campaign-edit";
import CampaignTicket from "./components/pages/campaigns/campaign-ticket";
import CampaignTicketDemo from "./components/pages/campaigns/campaign-ticket-demo";
import CampaignGroups from "./components/pages/campaigns/campaign-groups";
import CampaignGroup from "./components/pages/campaigns/campaign-group";
import ContractTicket from "./components/pages/campaigns/contract-ticket";
import Audiences from "./components/pages/campaigns/audiences";
import AudienceView from "./components/pages/campaigns/audience-view";
import AudienceNew from "./components/pages/campaigns/audience-new";
import AudienceEdit from "./components/pages/campaigns/audience-edit";
import Tickets from "./components/pages/tickets/tickets";
import TicketView from "./components/pages/tickets/ticket-view";
import TicketContact from "./components/pages/tickets/ticket-contact";
import TicketInformation from "./components/pages/tickets/ticket-information";
import TicketDetails from "./components/pages/tickets/ticket-details";
import TicketActivity from "./components/pages/tickets/ticket-activity";
import GroupsView from "./components/pages/groups/groups";
import GroupTicketsView from "./components/pages/groups/group-tickets-view";
import Contacts from "./components/pages/contacts/contacts";
import ContactNew from "./components/pages/contacts/contact-new";
import ContactView from "./components/pages/contacts/contact-view";
import ContactEdit from "./components/pages/contacts/contact-edit";
import ContactTicket from "./components/pages/contacts/contact-ticket";
import Knowledge from "./components/pages/knowledge/knowledge";
import KnowledgeNew from "./components/pages/knowledge/knowledge-new";
import KnowledgeView from "./components/pages/knowledge/knowledge-view";
import KnowledgeEdit from "./components/pages/knowledge/knowledge-edit";
import Settings from "./components/pages/settings/settings";
import Users from "./components/pages/settings/users/users";
import UserNew from "./components/pages/settings/users/user-new";
import UserView from "./components/pages/settings/users/user-view";
import UserEdit from "./components/pages/settings/users/user-edit";
import Groups from "./components/pages/settings/groups/groups";
import Options from "./components/pages/settings/options/options";
import DashboardColumns from "./components/pages/settings/options/dashboard-columns/dashboard-columns";
import PhoneCountries from "./components/pages/settings/options/phone-countries/phone-countries";
import PhoneCountryNew from "./components/pages/settings/options/phone-countries/phone-country-new";
import PhoneCountryView from "./components/pages/settings/options/phone-countries/phone-country-view";
import PhoneCountryEdit from "./components/pages/settings/options/phone-countries/phone-country-edit";
import PhoneAreas from "./components/pages/settings/options/phone-areas/phone-areas";
import PhoneAreaNew from "./components/pages/settings/options/phone-areas/phone-area-new";
import PhoneAreaView from "./components/pages/settings/options/phone-areas/phone-area-view";
import PhoneAreaEdit from "./components/pages/settings/options/phone-areas/phone-area-edit";
import Fields from "./components/pages/settings/fields/fields";
import ContactFields from "./components/pages/settings/fields/contact-fields/contact-fields";
import ContactFieldNew from "./components/pages/settings/fields/contact-fields/contact-field-new";
import ContactFieldView from "./components/pages/settings/fields/contact-fields/contact-field-view";
import ContactFieldEdit from "./components/pages/settings/fields/contact-fields/contact-field-edit";
import ActivityFields from "./components/pages/settings/fields/activity-fields/activity-fields";
import ActivityFieldNew from "./components/pages/settings/fields/activity-fields/activity-field-new";
import ActivityFieldView from "./components/pages/settings/fields/activity-fields/activity-field-view";
import ActivityFieldEdit from "./components/pages/settings/fields/activity-fields/activity-field-edit";
import SharedFields from "./components/pages/settings/fields/shared-fields/shared-fields";
import ChoiceFields from "./components/pages/settings/fields/shared-fields/choice-fields/choice-fields";
import ChoiceFieldNew from "./components/pages/settings/fields/shared-fields/choice-fields/choice-field-new";
import ChoiceFieldView from "./components/pages/settings/fields/shared-fields/choice-fields/choice-field-view";
import ChoiceFieldEdit from "./components/pages/settings/fields/shared-fields/choice-fields/choice-field-edit";
import FormFields from "./components/pages/settings/fields/shared-fields/form-fields/form-fields";
import FormFieldNew from "./components/pages/settings/fields/shared-fields/form-fields/form-field-new";
import FormFieldView from "./components/pages/settings/fields/shared-fields/form-fields/form-field-view";
import FormFieldEdit from "./components/pages/settings/fields/shared-fields/form-fields/form-field-edit";
import Logs from "./components/pages/settings/logs/logs";
import Blacklist from "./components/pages/settings/blacklist/blacklist-view";
import Conditions from "./components/pages/settings/conditions/conditions-view";
export const publicRoutes = [
    {
        path: "/login",
        element: LogIn,
    },
    {
        path: "/forgot-password",
        element: ForgotPassword,
    },
    {
        path: "/reset-password",
        element: ResetPassword,
    },
    {
        path: "/anketa/:slug",
        element: SurveyTicket,
    },
    {
        path: "/survey/:slug",
        element: SurveyTicket,
    },
    {
        path: "/anketa/:slug/:contact_uuid",
        element: SurveyTicket,
    },
    {
        path: "/survey/:slug/:contact_uuid",
        element: SurveyTicket,
    },
    {
        path: "/activity/:ticket_uuid",
        element: ActivityTicket,
    },
    {
        path: "/aktivnost/:ticket_uuid",
        element: ActivityTicket,
    },
    {
        path: "/activity/:ticket_uuid/:user_uuid",
        element: ActivityTicket,
    },
    {
        path: "/aktivnost/:ticket_uuid/:user_uuid",
        element: ActivityTicket,
    },
];
export const generalRoutes = [
    {
        path: "/",
        element: Home,
        roles: ["super_admin", "user"],
    },
    {
        path: "/3cx/:callerDisplayName",
        element: ThreeCX,
        roles: ["super_admin", "user"],
    },
    {
        path: "/3cx/:callerDisplayName/:callerNumber",
        element: ThreeCX,
        roles: ["super_admin", "user"],
    },
    {
        path: "/administration",
        element: Administration,
        roles: ["super_admin"],
    },
    {
        path: "/administration/clients",
        element: Clients,
        roles: ["super_admin"],
    },
    {
        path: "/administration/clients/new",
        element: ClientNew,
        roles: ["super_admin"],
    },
    {
        path: "/administration/clients/:id",
        element: ClientView,
        roles: ["super_admin"],
    },
    {
        path: "/administration/clients/:id/edit",
        element: ClientEdit,
        roles: ["super_admin"],
    },
    {
        path: "/administration/users",
        element: CentralUsers,
        roles: ["super_admin"],
    },
    {
        path: "/administration/users/new",
        element: CentralUserNew,
        roles: ["super_admin"],
    },
    {
        path: "/administration/users/:id",
        element: CentralUserView,
        roles: ["super_admin"],
    },
    {
        path: "/administration/users/:id/edit",
        element: CentralUserEdit,
        roles: ["super_admin"],
    },
    {
        path: "/administration/groups",
        element: CentralGroups,
        roles: ["super_admin"],
    },
    {
        path: "/administration/logs",
        element: CentralLogs,
        roles: ["super_admin"],
    },
    {
        path: "/administration/blacklist",
        element: CentralBlacklist,
        roles: ["super_admin"],
    },
];
export const tenantRoutes = [
    {
        path: "/account",
        element: Account,
        roles: ["admin", "manager", "agent"],
    },
    {
        path: "/account/edit",
        element: AccountEdit,
        roles: ["admin", "manager", "agent"],
    },
    {
        path: "/notifications",
        element: UserNotifications,
        roles: ["admin", "manager", "agent"],
    },
    {
        path: "/dashboard",
        element: Dashboard,
        roles: ["admin", "manager", "agent"],
    },
    {
        path: "/campaigns",
        element: Campaigns,
        roles: ["admin", "manager", "agent"],
    },
    {
        path: "/campaigns/new",
        element: CampaignNew,
        roles: ["admin"],
    },
    {
        path: "/campaigns/:id",
        element: CampaignView,
        roles: ["admin", "manager", "agent"],
    },
    {
        path: "/campaigns/:id/edit",
        element: CampaignEdit,
        roles: ["admin"],
    },
    {
        path: "/campaigns/:id/new-ticket",
        element: CampaignTicket,
        roles: ["admin", "manager", "agent"],
    },
    {
        path: "/campaigns/:id/demo-ticket",
        element: CampaignTicketDemo,
        roles: ["admin"],
    },
    {
        path: "/campaigns/:id/new-ticket/:phone",
        element: CampaignTicket,
        roles: ["admin", "manager", "agent"],
    },
    {
        path: "/campaigns/:id/groups",
        element: CampaignGroups,
        roles: ["admin", "manager", "agent"],
    },
    {
        path: "/campaigns/:campaignId/groups/:groupId",
        element: CampaignGroup,
        roles: ["admin", "manager", "agent"],
    },
    {
        path: "/campaigns/:campaignId/contacts/:contactId/new-ticket",
        element: ContractTicket,
        roles: ["admin", "manager", "agent"],
    },
    {
        path: "/campaigns/:id/audiences",
        element: Audiences,
        roles: ["admin", "manager"],
    },
    {
        path: "/campaigns/:campaignId/audiences/:audienceId",
        element: AudienceView,
        roles: ["admin", "manager"],
    },
    {
        path: "/campaigns/:id/audience/new",
        element: AudienceNew,
        roles: ["admin", "manager"],
    },
    {
        path: "/campaigns/:campaignId/audiences/:audienceId/edit",
        element: AudienceEdit,
        roles: ["admin", "manager"],
    },
    {
        path: "/tickets",
        element: Tickets,
        roles: ["admin", "manager"],
    },
    {
        path: "/tickets/:id",
        element: TicketView,
        roles: ["admin", "manager", "agent"],
    },
    {
        path: "/tickets/:id/contact",
        element: TicketContact,
        roles: ["admin", "manager", "agent"],
    },
    {
        path: "/tickets/:id/information",
        element: TicketInformation,
        roles: ["admin", "manager", "agent"],
    },
    {
        path: "/tickets/:id/details",
        element: TicketDetails,
        roles: ["admin", "manager", "agent"],
    },
    {
        path: "/tickets/:id/activity",
        element: TicketActivity,
        roles: ["admin", "manager", "agent"],
    },
    {
        path: "/groups",
        element: GroupsView,
        roles: ["admin", "manager", "agent"],
    },
    {
        path: "/groups/:groupId",
        element: GroupTicketsView,
        roles: ["admin", "manager", "agent"],
    },
    {
        path: "/contacts",
        element: Contacts,
        roles: ["admin", "manager"],
    },
    {
        path: "/contacts/new",
        element: ContactNew,
        roles: ["admin", "manager"],
    },
    {
        path: "/contacts/:id",
        element: ContactView,
        roles: ["admin", "manager"],
    },
    {
        path: "/contacts/:id/edit",
        element: ContactEdit,
        roles: ["admin", "manager"],
    },
    {
        path: "/contacts/:contactId/campaign/:campaignId/new-ticket",
        element: ContactTicket,
        roles: ["admin", "manager"],
    },
    {
        path: "/knowledge",
        element: Knowledge,
        roles: ["admin", "manager", "agent"],
    },
    {
        path: "/knowledge/new",
        element: KnowledgeNew,
        roles: ["admin", "manager"],
    },
    {
        path: "/knowledge/:id",
        element: KnowledgeView,
        roles: ["admin", "manager", "agent"],
    },
    {
        path: "/knowledge/:id/edit",
        element: KnowledgeEdit,
        roles: ["admin", "manager"],
    },
    {
        path: "/settings",
        element: Settings,
        roles: ["admin", "manager"],
    },
    {
        path: "/settings/users",
        element: Users,
        roles: ["admin", "manager"],
    },
    {
        path: "/settings/users/new",
        element: UserNew,
        roles: ["admin", "manager"],
    },
    {
        path: "/settings/users/:id",
        element: UserView,
        roles: ["admin", "manager"],
    },
    {
        path: "/settings/users/:id/edit",
        element: UserEdit,
        roles: ["admin", "manager"],
    },
    {
        path: "/settings/groups",
        element: Groups,
        roles: ["admin", "manager"],
    },
    {
        path: "/settings/options",
        element: Options,
        roles: ["admin"],
    },
    {
        path: "/settings/options/dashboard-columns",
        element: DashboardColumns,
        roles: ["admin"],
    },
    {
        path: "/settings/options/phone-countries",
        element: PhoneCountries,
        roles: ["admin"],
    },
    {
        path: "/settings/options/phone-countries/new",
        element: PhoneCountryNew,
        roles: ["admin"],
    },
    {
        path: "/settings/options/phone-countries/:id",
        element: PhoneCountryView,
        roles: ["admin"],
    },
    {
        path: "/settings/options/phone-countries/:id/edit",
        element: PhoneCountryEdit,
        roles: ["admin"],
    },
    {
        path: "/settings/options/phone-areas",
        element: PhoneAreas,
        roles: ["admin"],
    },
    {
        path: "/settings/options/phone-areas/new",
        element: PhoneAreaNew,
        roles: ["admin"],
    },
    {
        path: "/settings/options/phone-areas/:id",
        element: PhoneAreaView,
        roles: ["admin"],
    },
    {
        path: "/settings/options/phone-areas/:id/edit",
        element: PhoneAreaEdit,
        roles: ["admin"],
    },
    {
        path: "/settings/fields",
        element: Fields,
        roles: ["admin"],
    },
    {
        path: "/settings/fields/contacts",
        element: ContactFields,
        roles: ["admin"],
    },
    {
        path: "/settings/fields/contacts/new",
        element: ContactFieldNew,
        roles: ["admin"],
    },
    {
        path: "/settings/fields/contacts/:id",
        element: ContactFieldView,
        roles: ["admin"],
    },
    {
        path: "/settings/fields/contacts/:id/edit",
        element: ContactFieldEdit,
        roles: ["admin"],
    },
    {
        path: "/settings/fields/activities",
        element: ActivityFields,
        roles: ["admin"],
    },
    {
        path: "/settings/fields/activities/new",
        element: ActivityFieldNew,
        roles: ["admin"],
    },
    {
        path: "/settings/fields/activities/:id",
        element: ActivityFieldView,
        roles: ["admin"],
    },
    {
        path: "/settings/fields/activities/:id/edit",
        element: ActivityFieldEdit,
        roles: ["admin"],
    },
    {
        path: "/settings/fields/shared",
        element: SharedFields,
        roles: ["admin"],
    },
    {
        path: "/settings/fields/shared/choices",
        element: ChoiceFields,
        roles: ["admin"],
    },
    {
        path: "/settings/fields/shared/choices/new",
        element: ChoiceFieldNew,
        roles: ["admin"],
    },
    {
        path: "/settings/fields/shared/choices/:id",
        element: ChoiceFieldView,
        roles: ["admin"],
    },
    {
        path: "/settings/fields/shared/choices/:id/edit",
        element: ChoiceFieldEdit,
        roles: ["admin"],
    },
    {
        path: "/settings/fields/shared/forms",
        element: FormFields,
        roles: ["admin"],
    },
    {
        path: "/settings/fields/shared/forms/new",
        element: FormFieldNew,
        roles: ["admin"],
    },
    {
        path: "/settings/fields/shared/forms/:id",
        element: FormFieldView,
        roles: ["admin"],
    },
    {
        path: "/settings/fields/shared/forms/:id/edit",
        element: FormFieldEdit,
        roles: ["admin"],
    },
    {
        path: "/settings/logs",
        element: Logs,
        roles: ["admin"],
    },
    {
        path: "/settings/blacklist",
        element: Blacklist,
        roles: ["admin"],
    },
    {
        path: "/settings/notifications",
        element: TenantNotifications,
        roles: ["admin"],
    },
    {
        path: "/settings/conditions",
        element: Conditions,
        roles: ["admin"],
    },
];
