import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ContactsIcon from "@mui/icons-material/PermContactCalendar";
import CampaignIcon from "@mui/icons-material/Campaign";
import ShareIcon from "@mui/icons-material/Share";
import Intro from "../../../elements/blocks/intro";
import { useTenancy } from "../../../../services/tenancy";
export default function Fields() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const tenancy = useTenancy();
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("fields.fields"), breadcrumbs: [
                    {
                        name: t("settings"),
                        link: "/settings",
                    },
                    {
                        name: t("fields.fields"),
                        link: "/settings/fields",
                    },
                ] }), _jsx(Fragment, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4, lg: 3 }, { children: _jsx(Card, { children: _jsx(CardActionArea, Object.assign({ onClick: () => {
                                        navigate(tenancy.link("/settings/fields/contacts"));
                                    } }, { children: _jsx(CardContent, { children: _jsxs(Box, Object.assign({ sx: {
                                                height: "120px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                flexDirection: "column",
                                                textAlign: "center",
                                            } }, { children: [_jsx(ContactsIcon, { style: {
                                                        fontSize: 50,
                                                        marginBottom: "8px",
                                                    } }), _jsx(Typography, Object.assign({ variant: "h5" }, { children: t("fields.contact-fields.contact-fields") }))] })) }) })) }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4, lg: 3 }, { children: _jsx(Card, { children: _jsx(CardActionArea, Object.assign({ onClick: () => {
                                        navigate(tenancy.link("/settings/fields/activities"));
                                    } }, { children: _jsx(CardContent, { children: _jsxs(Box, Object.assign({ sx: {
                                                height: "120px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                flexDirection: "column",
                                            } }, { children: [_jsx(CampaignIcon, { style: {
                                                        fontSize: 50,
                                                        marginBottom: "8px",
                                                    } }), _jsx(Typography, Object.assign({ variant: "h5" }, { children: t("fields.activity-fields.activity-fields") }))] })) }) })) }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4, lg: 3 }, { children: _jsx(Card, { children: _jsx(CardActionArea, Object.assign({ onClick: () => {
                                        navigate(tenancy.link("/settings/fields/shared"));
                                    } }, { children: _jsx(CardContent, { children: _jsxs(Box, Object.assign({ sx: {
                                                height: "120px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                flexDirection: "column",
                                                textAlign: "center",
                                            } }, { children: [_jsx(ShareIcon, { style: {
                                                        fontSize: 50,
                                                        marginBottom: "8px",
                                                    } }), _jsx(Typography, Object.assign({ variant: "h5" }, { children: t("fields.shared-fields.shared-fields") }))] })) }) })) }) }))] })) })] }));
}
