import { useState, useEffect } from "react";
import { createSingletonHook } from "../helpers/singleton";
export const [usePrefs, PrefsProvider] = createSingletonHook(() => {
    const [lang, setLang] = useState(localStorage.getItem("lang") === "hr" ? "hr" : "en");
    const [theme, setTheme] = useState(localStorage.getItem("theme") === "dark" ? "dark" : "light");
    const [notificationPermission, setNotificationPermission] = useState("default");
    const [ticketStartTime, setTicketStartTime] = useState(sessionStorage.getItem("ticketStartTime"));
    const [ticketPhone, setTicketPhone] = useState(sessionStorage.getItem("ticketPhone"));
    const [surveys, setSurveys] = useState(localStorage.getItem("surveys"));
    const [pageSize, setPageSize] = useState(
    // @ts-ignore
    localStorage.getItem("pageSize") !== null ? localStorage.getItem("pageSize") : "5");
    useEffect(() => {
        localStorage.setItem("lang", lang);
    }, [lang]);
    useEffect(() => {
        localStorage.setItem("theme", theme);
    }, [theme]);
    useEffect(() => {
        if (ticketStartTime)
            sessionStorage.setItem("ticketStartTime", ticketStartTime);
        else
            sessionStorage.removeItem("ticketStartTime");
    }, [ticketStartTime]);
    useEffect(() => {
        if (ticketPhone)
            sessionStorage.setItem("ticketPhone", ticketPhone);
        else
            sessionStorage.removeItem("ticketPhone");
    }, [ticketPhone]);
    useEffect(() => {
        if (surveys)
            localStorage.setItem("surveys", surveys);
        else
            localStorage.setItem("surveys", "");
    }, [surveys]);
    useEffect(() => {
        if (pageSize)
            localStorage.setItem("pageSize", pageSize);
        else
            localStorage.setItem("pageSize", "5");
    }, [pageSize]);
    return {
        lang,
        setLang,
        theme,
        setTheme,
        notificationPermission,
        setNotificationPermission,
        ticketStartTime,
        setTicketStartTime,
        ticketPhone,
        setTicketPhone,
        surveys,
        setSurveys,
        pageSize,
        setPageSize,
    };
});
