import { useState, useEffect } from "react";
import { createSingletonHook } from "../helpers/singleton";
import { useAuth } from "./auth";
import { useTenancy } from "./tenancy";
import { useApiTenant } from "../api/api-tenant";
export const [useRoles, RolesProvider] = createSingletonHook(() => {
    const auth = useAuth();
    const tenancy = useTenancy();
    const api = useApiTenant();
    const [isLoading, setIsLoading] = useState(true);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [isUser, setIsUser] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isManager, setIsManager] = useState(false);
    const [isAgent, setIsAgent] = useState(false);
    const [roleCentral, setRoleCentral] = useState(null);
    const [roleTenant, setRoleTenant] = useState(null);
    const [groupsCentral, setGroupsCentral] = useState(null);
    const [groupsTenant, setGroupsTenant] = useState(null);
    const [userCentral, setUserCentral] = useState(null);
    const [userTenant, setUserTenant] = useState(null);
    useEffect(() => {
        if (!auth.user)
            return;
        setUserCentral(auth.user);
        const role = auth.user.roles[0] ? auth.user.roles[0].name : "user";
        // @ts-ignore
        setRoleCentral(role);
        if (role === "super_admin")
            setIsSuperAdmin(true);
        else
            setIsUser(true);
        if (auth.user.groups && auth.user.groups.length > 0) {
            setGroupsCentral(auth.user.groups);
        }
    }, [auth.user]);
    useEffect(() => {
        if (!tenancy.client || auth.isPublic) {
            setIsLoading(false);
            return;
        }
        setIsLoading(true);
        api.GET_currentUser()
            .then(response => {
            setUserTenant(response.data.tenant_user);
            const role = response.data.tenant_user.roles[0]
                ? response.data.tenant_user.roles[0].name
                : "agent";
            setRoleTenant(role);
            setIsAdmin(false);
            setIsManager(false);
            setIsAgent(false);
            if (role === "admin")
                setIsAdmin(true);
            else if (role === "manager")
                setIsManager(true);
            else if (role === "agent")
                setIsAgent(true);
            if (response.data.tenant_user.groups &&
                response.data.tenant_user.groups.length > 0) {
                setGroupsTenant(response.data.tenant_user.groups);
            }
        })
            .catch(error => {
            console.error(error);
        })
            .finally(() => {
            setIsLoading(false);
        });
    }, [tenancy.client]);
    return {
        isLoading,
        // Central
        userCentral,
        roleCentral,
        groupsCentral,
        isSuperAdmin,
        setIsSuperAdmin,
        isUser,
        // Tenant
        userTenant,
        setUserTenant,
        roleTenant,
        setRoleTenant,
        groupsTenant,
        setGroupsTenant,
        isAdmin,
        setIsAdmin,
        isManager,
        setIsManager,
        isAgent,
        setIsAgent,
    };
});
