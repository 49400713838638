import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import EditIcon from "@mui/icons-material/Settings";
import AccountIcon from "@mui/icons-material/AccountCircle";
import { useApiTenant } from "../../../../api/api-tenant";
import { useTenancy } from "../../../../services/tenancy";
import { useRoles } from "../../../../services/roles";
import { useAlert } from "../../../../services/alert";
import { flatGroups } from "../../../../helpers/groups";
export default function AssigneeColumn({ type, ticket, ticketAssignees, isPreview, refreshTableData, }) {
    var _a, _b;
    const roles = useRoles();
    const navigate = useNavigate();
    const tenancy = useTenancy();
    const { t } = useTranslation();
    const api = useApiTenant();
    const alert = useAlert();
    const [assigneeDialogOpen, setAssigneeDialogOpen] = useState(false);
    const [isSavingTicketDetail, setIsSavingTicketDetail] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isUsersLoading, setIsUsersLoading] = useState(false);
    const [errors] = useState(null);
    const [usersWithGroups, setUsersWithGroups] = useState([]);
    const [assignedUsersWithGroups, setAssignedUsersWithGroups] = useState([]);
    const updateTicketAssignee = () => {
        setIsSavingTicketDetail(true);
        api.CREATE_ticketDetail(ticket.id, undefined, undefined, assignedUsersWithGroups.map(group => group.user.id))
            .then(() => {
            refreshTableData();
            alert.show(t("tickets.tickets-table.assignee-updated"), "success");
        })
            .catch(error => {
            setIsSavingTicketDetail(false);
            if (error.response && error.response.data && error.response.data.errors) {
                console.error(error.response);
            }
            alert.show(t("tickets.tickets-table.updating-status-error"), "error");
        });
    };
    const resetAssigneeDialog = () => {
        setAssigneeDialogOpen(false);
        setIsSavingTicketDetail(false);
        setTimeout(() => {
            setAssignedUsersWithGroups([]);
        }, 300);
    };
    useEffect(() => {
        if (assigneeDialogOpen) {
            setIsUsersLoading(true);
            if (ticket.groups && ticket.groups.length > 0) {
                api.GET_groupUsers(undefined, 10000, 1, undefined, undefined, undefined, ticket.groups.map(group => Number(group.id)), 1)
                    .then(response => {
                    const usersWithGroups = [];
                    response.data.tenant_users.map((user) => {
                        var _a;
                        const diff = [];
                        (_a = user.parent_groups) === null || _a === void 0 ? void 0 : _a.map(group => {
                            const groups = flatGroups([group], 0, []);
                            diff.push({ group: group, parentGroups: groups });
                        });
                        usersWithGroups.push({
                            user: user,
                            diffGroups: diff,
                        });
                    });
                    if (ticketAssignees) {
                        const newAssignedUsersWithGroups = usersWithGroups.filter(userGroups => ticketAssignees.some(assignee => userGroups.user.id === assignee.id));
                        if (newAssignedUsersWithGroups) {
                            setAssignedUsersWithGroups(newAssignedUsersWithGroups);
                        }
                    }
                    setUsersWithGroups(usersWithGroups);
                    setIsUsersLoading(false);
                })
                    .catch(error => {
                    if (!api.isCancel(error))
                        console.error(error);
                });
            }
            else {
                api.GET_users(10000, 1, "first_name", undefined, undefined, undefined, undefined, undefined, 1)
                    .then(response => {
                    const usersWithGroups = [];
                    response.data.tenant_users.map((user) => {
                        var _a;
                        const diff = [];
                        (_a = user.parent_groups) === null || _a === void 0 ? void 0 : _a.map(group => {
                            const groups = flatGroups([group], 0, []);
                            diff.push({ group: group, parentGroups: groups });
                        });
                        usersWithGroups.push({
                            user: user,
                            diffGroups: diff,
                        });
                    });
                    if (ticketAssignees) {
                        const newAssignedUsersWithGroups = usersWithGroups.filter(userGroups => ticketAssignees.some(assignee => userGroups.user.id === assignee.id));
                        if (newAssignedUsersWithGroups) {
                            setAssignedUsersWithGroups(newAssignedUsersWithGroups);
                        }
                    }
                    setUsersWithGroups(usersWithGroups);
                    setIsUsersLoading(false);
                })
                    .catch(error => {
                    if (!api.isCancel(error))
                        console.error(error);
                });
            }
            return () => {
                api.cancel();
            };
        }
    }, [ticket, assigneeDialogOpen]);
    return (_jsxs(Fragment, { children: [ticketAssignees.length === 0 ? (_jsx(Chip, { label: t("tickets.tickets-table.unassigned"), variant: "outlined", size: "small", style: {
                    color: ticket.campaign &&
                        ticket.campaign.field_options &&
                        ticket.campaign.field_options.length > 0 &&
                        ((_a = ticket.campaign.field_options.find(a => a.data === "assignee_ids")) === null || _a === void 0 ? void 0 : _a.visible) === false
                        ? "#bebebe"
                        : "inherit",
                }, icon: _jsx(AccountIcon, { style: {
                        color: "#bebebe",
                    } }), deleteIcon: type !== "history" &&
                    !isPreview &&
                    ticket.campaign.field_options &&
                    ticket.campaign.field_options.length > 0 &&
                    ((_b = ticket.campaign.field_options.find(a => a.data === "assignee_ids")) === null || _b === void 0 ? void 0 : _b.visible) === true ? (_jsx(Tooltip, Object.assign({ arrow: true, placement: "top", 
                    // @ts-ignore
                    title: t("tickets.tickets-table.edit-assignee") }, { children: _jsx(EditIcon, {}) }))) : (_jsx(Fragment, {})), onDelete: () => {
                    setAssigneeDialogOpen(true);
                } })) : (_jsx(Fragment, { children: ticketAssignees.map(assignee => {
                    var _a, _b, _c;
                    return (_jsx(Chip, { onClick: roles.isAdmin || roles.isManager
                            ? () => navigate(tenancy.link("/settings/users/" + assignee.id))
                            : undefined, label: assignee.first_name +
                            " " +
                            (assignee.last_name ? assignee.last_name : ""), variant: "outlined", size: "small", style: {
                            marginTop: "4px",
                            marginBottom: "4px",
                            marginRight: "8px",
                            wordBreak: "break-word",
                            color: ticket.campaign &&
                                ticket.campaign.field_options &&
                                ticket.campaign.field_options.length > 0 &&
                                ((_a = ticket.campaign.field_options.find(a => a.data === "assignee_ids")) === null || _a === void 0 ? void 0 : _a.visible) === false
                                ? "#bebebe"
                                : "inherit",
                        }, icon: _jsx(Tooltip, Object.assign({ arrow: true, placement: "left", title: assignee.email.substring(assignee.email.indexOf("@") + 1) }, { children: _jsx(AccountIcon, { style: {
                                    color: assignee.id === ((_b = roles.userTenant) === null || _b === void 0 ? void 0 : _b.id)
                                        ? "#4caf50"
                                        : assignee.email.includes("modernekomunikacije.hr") || assignee.email.includes("significo.hr")
                                            ? "#254092"
                                            : "#bebebe",
                                } }) })), deleteIcon: type !== "history" &&
                            !isPreview &&
                            ticket.campaign.field_options &&
                            ticket.campaign.field_options.length > 0 &&
                            ((_c = ticket.campaign.field_options.find(a => a.data === "assignee_ids")) === null || _c === void 0 ? void 0 : _c.visible) === true ? (_jsx(Tooltip, Object.assign({ arrow: true, placement: "top", 
                            // @ts-ignore
                            title: t("tickets.tickets-table.edit-assignee") }, { children: _jsx(EditIcon, {}) }))) : (_jsx(Fragment, {})), onDelete: () => {
                            setAssigneeDialogOpen(true);
                        } }, assignee.id));
                }) })), _jsxs(Dialog, Object.assign({ fullWidth: true, maxWidth: "xs", open: assigneeDialogOpen, onClose: resetAssigneeDialog }, { children: [_jsx(DialogContent, Object.assign({ sx: {
                            paddingTop: "32px !important",
                        } }, { children: _jsx(Autocomplete, { multiple: true, options: usersWithGroups, getOptionLabel: userGroups => {
                                var _a;
                                return (userGroups.user.first_name ? userGroups.user.first_name + " " : "") +
                                    ((_a = userGroups.user.last_name) !== null && _a !== void 0 ? _a : "");
                            }, isOptionEqualToValue: (option, value) => option && option.user.email === value.user.email, value: assignedUsersWithGroups, onChange: (event, value) => {
                                setAssignedUsersWithGroups(value);
                                if (isError && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("assignee_ids")))
                                    setIsError(false);
                            }, loading: isUsersLoading, renderOption: (props, userGroups) => {
                                var _a, _b;
                                return (_jsx("li", Object.assign({}, props, { children: _jsxs(Box, Object.assign({ sx: {
                                            display: "flex",
                                            flexDirection: "column",
                                        } }, { children: [_jsxs(Box, Object.assign({ sx: {
                                                    display: "flex",
                                                } }, { children: [_jsx(AccountIcon, { style: {
                                                            color: userGroups.user.id === ((_a = roles.userTenant) === null || _a === void 0 ? void 0 : _a.id)
                                                                ? "#4caf50"
                                                                : userGroups.user.email.includes("modernekomunikacije.hr") ||
                                                                    userGroups.user.email.includes("significo.hr")
                                                                    ? "#254092"
                                                                    : "#bebebe",
                                                            marginRight: 4,
                                                            marginTop: 2,
                                                        }, fontSize: "small" }), _jsx(Typography, Object.assign({ variant: "button" }, { children: (userGroups.user.first_name
                                                            ? userGroups.user.first_name + " "
                                                            : "") +
                                                            (userGroups.user.last_name
                                                                ? userGroups.user.last_name + " "
                                                                : "") }))] })), _jsx(Box, Object.assign({ sx: {
                                                    display: "flex",
                                                    whiteSpace: "pre-wrap",
                                                } }, { children: _jsx(Typography, Object.assign({ variant: "caption", color: "textPrimary" }, { children: usersWithGroups.find(u => u.user.id === userGroups.user.id)
                                                        ? (_b = usersWithGroups
                                                            .find(u => u.user.id === userGroups.user.id)) === null || _b === void 0 ? void 0 : _b.diffGroups.map(group => group.parentGroups
                                                            .map(a => a.name)
                                                            .reverse()
                                                            .join(" → ")
                                                            .replace(/^/, "• ")).reverse().join("\r\n")
                                                        : "" })) }))] }), userGroups.user.id) })));
                            }, renderInput: params => (_jsx(TextField, Object.assign({ required: ticket
                                    ? !ticket.campaign.field_options ||
                                        ticket.campaign.field_options.length === 0
                                        ? false
                                        : ticket.campaign.field_options.find(o => o.data === "assignee_ids" &&
                                            o.table === "ticket_details").required
                                    : false }, params, { label: t("tickets.ticket-attributes.assignee"), fullWidth: true, helperText: isError &&
                                    (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("assignee_ids")) &&
                                    errors["assignee_ids"][0], InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(Fragment, { children: [isUsersLoading ? (_jsx(CircularProgress, { color: "inherit", size: 20 })) : null, params.InputProps.endAdornment] })) }), error: isError && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("assignee_ids")) }))) }) })), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ color: "inherit", onClick: resetAssigneeDialog }, { children: t("cancel") })), _jsx(LoadingButton, Object.assign({ loading: isSavingTicketDetail, loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }), disabled: isUsersLoading ||
                                    (ticketAssignees.length === 0 && assignedUsersWithGroups.length === 0), onClick: updateTicketAssignee }, { children: t("tickets.tickets-table.edit-assignee") }))] })] }))] }));
}
