import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Intro from "../../../elements/blocks/intro";
import UsersMetrics from "../../../elements/users/users-metrics";
import UsersTable from "../../../elements/users/users-table";
import { useTenancy } from "../../../../services/tenancy";
import { useRoles } from "../../../../services/roles";
export default function Users() {
    const { t } = useTranslation();
    const tenancy = useTenancy();
    const roles = useRoles();
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("users.users"), breadcrumbs: [
                    {
                        name: t("settings"),
                        link: "/settings",
                    },
                    {
                        name: t("users.users"),
                        link: "/settings/users",
                    },
                ], button: !roles.isSuperAdmin
                    ? {
                        label: t("users.new-user"),
                        link: "/settings/users/new",
                        color: "secondary",
                        type: "new",
                    }
                    : undefined }), (roles.isManager || roles.isAdmin) && _jsx(UsersMetrics, {}), _jsx(UsersTable, { type: "client", title: t("users.users-table.all-users"), link: tenancy.link("/settings/users/") })] }));
}
