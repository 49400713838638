import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, Fragment, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import CopyContentIcon from "@mui/icons-material/ContentCopy";
import UsersIcon from "@mui/icons-material/People";
import RefreshIcon from "@mui/icons-material/Refresh";
import EditIcon from "@mui/icons-material/Settings";
import NewIcon from "@mui/icons-material/Add";
import FilterIcon from "@mui/icons-material/FilterAltOutlined";
import Table from "../blocks/table";
import InputRadio from "../inputs/input-radio";
import { useAlert } from "../../../services/alert";
import { useAuth } from "../../../services/auth";
import { useTenancy } from "../../../services/tenancy";
import { useApiCentral } from "../../../api/api-central";
import { useApiTenant } from "../../../api/api-tenant";
import { copy } from "../../../helpers/copy";
import { cleanText } from "../../../helpers/clean";
export default function ClientUsers({ client }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const alert = useAlert();
    const auth = useAuth();
    const tenancy = useTenancy();
    const apiCentral = useApiCentral();
    const apiTenant = useApiTenant(client.id);
    const tableRef = useRef();
    const [isLoading, setIsLoading] = useState(true);
    const [isEditLoading, setIsEditLoading] = useState(false);
    const [isEditLoading2, setIsEditLoading2] = useState(false);
    const [isEditOpened, setIsEditOpened] = useState(false);
    const [isDeleteOpened, setIsDeleteOpened] = useState(false);
    const [isManage, setIsManage] = useState(false);
    const [allUsers, setAllUsers] = useState([]);
    const [allClientUsers, setAllClientUsers] = useState([]);
    const [searchUsers, setSearchUsers] = useState("");
    const searchUsersRef = useRef(searchUsers);
    const [searchUsersBy, setSearchUsersBy] = useState("full_name");
    const [showUserFilters, setShowUserFilters] = useState(false);
    const [user, setUser] = useState(null);
    const [role, setRole] = useState("agent");
    const [tableQuery, setTableQuery] = useState();
    const isMobile = useMediaQuery("(max-width: 767px)");
    useEffect(() => {
        if (!isEditOpened)
            return;
        setIsEditLoading2(true);
        apiTenant
            .GET_users(10000, 1, "first_name")
            .then(response => {
            setAllClientUsers(response.data.tenant_users);
            setIsEditLoading2(false);
        })
            .catch(error => {
            if (!apiTenant.isCancel(error))
                console.error(error);
        });
        return () => {
            apiCentral.cancel();
            apiTenant.cancel();
        };
    }, [isEditOpened]);
    useEffect(() => {
        setAllUsers([]);
        if (searchUsers === "")
            return;
        setIsEditLoading(true);
        debounceSearchUsers(cleanText(searchUsersRef.current), searchUsersBy);
    }, [searchUsers]);
    const debounceSearchUsers = useCallback(debounce((search, searchUsersBy) => {
        getUsers(search, searchUsersBy);
    }, 700), []);
    const getUsers = (search, searchUsersBy) => {
        if (searchUsersRef.current !== "") {
            apiCentral
                .GET_users(25, 1, "id", "asc", search, searchUsersBy)
                .then(response => {
                setAllUsers(response.data.central_users);
                setIsEditLoading(false);
            })
                .catch(error => {
                if (!apiTenant.isCancel(error)) {
                    console.error(error);
                    setIsEditLoading(false);
                }
            });
        }
        else {
            setIsEditLoading(false);
        }
    };
    const refreshClientUsers = () => {
        var _a;
        // @ts-ignore
        (_a = tableRef === null || tableRef === void 0 ? void 0 : tableRef.current) === null || _a === void 0 ? void 0 : _a.onQueryChange();
    };
    const getClientUsers = query => {
        setTableQuery(query);
        return new Promise(resolve => {
            setIsLoading(true);
            apiTenant
                .GET_users(query.pageSize, query.page + 1, query.orderBy ? query.orderBy.field : null, query.orderDirection ? query.orderDirection : null, query.search ? query.search : null)
                .then(response => {
                resolve({
                    data: response.data.tenant_users,
                    page: response.data.meta.current_page - 1,
                    totalCount: response.data.meta.total,
                });
            })
                .catch(error => {
                console.error(error);
            })
                .finally(() => {
                setIsLoading(false);
            });
        });
    };
    const newClientUser = () => {
        if (!user)
            return;
        setIsLoading(true);
        apiCentral
            .CREATE_userOnClient(client.id, user.id, role)
            .then(() => {
            setIsLoading(false);
            refreshClientUsers();
            alert.show(t("users.user-clients.user-added"), "success");
            setUser(null);
            setRole("agent");
            refreshCurrentClients(user);
        })
            .catch(error => {
            setIsLoading(false);
            console.error(error.data);
            alert.show(t("users.user-clients.adding-error-occured"), "error");
            setUser(null);
            setRole("agent");
        });
    };
    const updateClientUser = () => {
        if (!user)
            return;
        setIsLoading(true);
        apiTenant
            .UPDATE_user(user.id, user.first_name, user.last_name, user.email, user.is_enabled, role)
            .then(() => {
            setIsLoading(false);
            refreshClientUsers();
            alert.show(t("clients.client-users.user-updated"), "success");
            setUser(null);
            setRole("agent");
            refreshCurrentClients(user);
        })
            .catch(error => {
            setIsLoading(false);
            console.error(error.data);
            alert.show(t("clients.client-users.updating-error-occured"), "error");
            setUser(null);
            setRole("agent");
        });
    };
    const deleteClientUser = () => {
        if (!user)
            return;
        setIsLoading(true);
        apiCentral
            .DELETE_userFromClient(client.id, user.global_id)
            .then(() => {
            setIsLoading(false);
            refreshClientUsers();
            alert.show(t("users.user-clients.user-removed"), "warning");
            setUser(null);
            setRole("agent");
            refreshCurrentClients(user);
        })
            .catch(error => {
            setIsLoading(false);
            console.error(error.data);
            alert.show(t("users.user-clients.removing-error-occured"), "error");
            setUser(null);
            setRole("agent");
        });
    };
    const refreshCurrentClients = user => {
        var _a;
        if ((user === null || user === void 0 ? void 0 : user.global_id) !== ((_a = auth.user) === null || _a === void 0 ? void 0 : _a.global_id))
            return;
        setTimeout(() => {
            alert.hide();
            tenancy.currentUserClients();
        }, 1000);
    };
    return (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                    paddingTop: 2,
                    marginBottom: 2,
                } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsxs(Box, Object.assign({ sx: {
                                    display: "flex",
                                } }, { children: [_jsx(Box, Object.assign({ sx: {
                                            marginRight: 2,
                                        } }, { children: _jsx(UsersIcon, { fontSize: "large" }) })), _jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h5" }, { children: t("users.users") })), _jsx(Typography, Object.assign({ color: "textSecondary" }, { children: t("clients.client-users.client-users") }))] })] })) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 8 }, { children: _jsx(Table, { title: t("clients.client-users.client-users"), actions: [
                                    {
                                        icon: () => _jsx(RefreshIcon, { color: "action" }),
                                        tooltip: t("clients.client-users.refresh-client-users"),
                                        isFreeAction: true,
                                        onClick: () => {
                                            refreshClientUsers();
                                        },
                                    },
                                    {
                                        icon: () => _jsx(NewIcon, { color: "action" }),
                                        tooltip: t("users.user-clients.add-user-to-client"),
                                        isFreeAction: true,
                                        onClick: () => {
                                            setUser(null);
                                            setRole("agent");
                                            setIsManage(false);
                                            setIsEditOpened(true);
                                        },
                                    },
                                    {
                                        icon: () => _jsx(EditIcon, { color: "action" }),
                                        tooltip: t("clients.client-users.manage-client-user"),
                                        onClick: (event, data) => {
                                            setUser(data);
                                            setRole(data.roles[0].name);
                                            setIsManage(true);
                                            setIsEditOpened(true);
                                        },
                                    },
                                ], columns: isMobile
                                    ? [
                                        {
                                            title: t("users.user") + " #",
                                            field: "id",
                                            defaultSort: "desc",
                                        },
                                    ]
                                    : [
                                        {
                                            maxWidth: "80px",
                                            title: "#",
                                            field: "id",
                                            status: "numeric",
                                            defaultSort: "desc",
                                        },
                                        {
                                            title: t("users.user-attributes.full-name"),
                                            sorting: false,
                                            render: user => {
                                                return (_jsx(Link, Object.assign({ color: "inherit", href: "/administration/users/" +
                                                        user.central_id, onClick: event => {
                                                        event.preventDefault();
                                                        navigate("/administration/users/" +
                                                            user.central_id);
                                                    }, underline: "hover" }, { children: user.first_name +
                                                        (user.last_name
                                                            ? " " + user.last_name
                                                            : "") })));
                                            },
                                        },
                                        {
                                            title: t("users.user-attributes.email"),
                                            field: "email",
                                            render: user => {
                                                return (_jsx(Chip, { variant: "outlined", size: "small", label: user.email, onDelete: () => {
                                                        copy(user.email);
                                                        alert.show(t("email-copied"), "info");
                                                    }, deleteIcon: _jsx(Tooltip, Object.assign({ arrow: true, placement: "top", 
                                                        // @ts-ignore
                                                        title: t("copy-email") }, { children: _jsx(CopyContentIcon, {}) })) }));
                                            },
                                        },
                                        {
                                            title: t("users.role"),
                                            sorting: false,
                                            render: user => {
                                                return user.roles[0] != null ? (_jsx(Chip, { label: user.roles[0].name === "admin"
                                                        ? t("users.roles.admin")
                                                        : user.roles[0].name === "manager"
                                                            ? t("users.roles.manager")
                                                            : t("users.roles.agent"), size: "small", style: {
                                                        backgroundColor: user.roles[0].name === "admin"
                                                            ? "#f44336"
                                                            : user.roles[0].name ===
                                                                "manager"
                                                                ? "#ff9800"
                                                                : "#2196f3",
                                                        color: "#fff",
                                                    } })) : ("");
                                            },
                                        },
                                    ], detailPanel: isMobile
                                    ? (user) => {
                                        return (_jsx(Box, Object.assign({ sx: {
                                                paddingX: 2,
                                                paddingY: 3,
                                            } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("users.user-attributes.full-name") })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: user.first_name +
                                                                    (user.last_name
                                                                        ? " " + user.last_name
                                                                        : "") }))] })), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("users.user-attributes.email") })), _jsx(Box, Object.assign({ sx: {
                                                                    paddingTop: 0.5,
                                                                } }, { children: _jsx(Chip, { label: user.email, variant: "outlined", size: "small", onDelete: () => {
                                                                        copy(user.email);
                                                                        alert.show(t("email-copied"), "info");
                                                                    }, deleteIcon: _jsx(Tooltip, Object.assign({ arrow: true, placement: "top", 
                                                                        // @ts-ignore
                                                                        title: t("copy-email") }, { children: _jsx(CopyContentIcon, {}) })) }) }))] })), user.roles.length > 0 && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("users.role") })), _jsx(Box, Object.assign({ sx: {
                                                                    paddingTop: 0.5,
                                                                } }, { children: _jsx(Chip, { label: user.roles[0].name ===
                                                                        "admin"
                                                                        ? t("users.roles.admin")
                                                                        : user.roles[0]
                                                                            .name ===
                                                                            "manager"
                                                                            ? t("users.roles.manager")
                                                                            : t("users.roles.agent"), size: "small", style: {
                                                                        backgroundColor: user.roles[0].name ===
                                                                            "admin"
                                                                            ? "#f44336"
                                                                            : user.roles[0]
                                                                                .name ===
                                                                                "manager"
                                                                                ? "#ff9800"
                                                                                : user.roles[0]
                                                                                    .name ===
                                                                                    "super_admin"
                                                                                    ? "#dc004e"
                                                                                    : "#2196f3",
                                                                        color: "#fff",
                                                                    } }) }))] })))] })) })));
                                    }
                                    : undefined, data: query => getClientUsers(query), isLoading: isLoading, tableRef: tableRef, tableQuery: tableQuery }) }))] })) })), _jsxs(Dialog, Object.assign({ fullWidth: true, maxWidth: "xs", open: isEditOpened, onClose: () => {
                    setIsEditOpened(false);
                } }, { children: [_jsx(DialogTitle, { children: (isManage
                            ? t("clients.client-users.manage-user-on")
                            : t("clients.client-users.add-user-to")) + client.name }), _jsx(DialogContent, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Autocomplete, { disabled: isManage, options: allUsers, getOptionLabel: user => (user.first_name ? user.first_name + " " : "") +
                                            (user.last_name ? user.last_name + " " : "") +
                                            (user.email ? "(" + user.email + ")" : ""), isOptionEqualToValue: (option, value) => option.email === value.email, getOptionDisabled: option => {
                                            let user = allClientUsers.find(user => {
                                                return option.email === user.email;
                                            });
                                            return !!user;
                                        }, value: user, onChange: (event, value) => {
                                            setSearchUsers("");
                                            searchUsersRef.current = "";
                                            setUser(value);
                                        }, loading: isEditLoading && isEditLoading2, filterSelectedOptions: true, filterOptions: options => options, noOptionsText: t("filters.search-users-by") +
                                            " (" +
                                            (searchUsersBy === "full_name"
                                                ? t("users.user-attributes.full-name").toLocaleLowerCase()
                                                : searchUsersBy === "email"
                                                    ? t("users.user-attributes.email").toLocaleLowerCase()
                                                    : "ID") +
                                            ")", onInputChange: (event, value) => {
                                            setSearchUsers(value);
                                            searchUsersRef.current = value;
                                        }, renderOption: (props, user) => (_jsx("li", Object.assign({}, props, { children: _jsxs(Box, Object.assign({ sx: {
                                                    display: "flex",
                                                    flexDirection: "column",
                                                } }, { children: [_jsx(Box, Object.assign({ sx: {
                                                            display: "flex",
                                                        } }, { children: _jsx(Typography, Object.assign({ variant: "button" }, { children: (user.first_name
                                                                ? user.first_name + " "
                                                                : "") +
                                                                (user.last_name
                                                                    ? user.last_name + " "
                                                                    : "") })) })), _jsx(Box, Object.assign({ sx: {
                                                            display: "flex",
                                                        } }, { children: _jsx(Typography, Object.assign({ variant: "caption", color: "textPrimary" }, { children: user.email })) }))] }), user.id) }))), renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("clients.client-users.select-user"), required: true, fullWidth: true, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(InputAdornment, Object.assign({ position: "end", style: {
                                                        position: "absolute",
                                                        right: "16px",
                                                    } }, { children: [searchUsers.length > 0 && (_jsx(Fragment, { children: isEditLoading && (_jsx(IconButton, Object.assign({ edge: "end", onClick: () => {
                                                                    setSearchUsers("");
                                                                    searchUsersRef.current = "";
                                                                }, size: "large" }, { children: _jsx(CircularProgress, { color: "inherit", size: 18, thickness: 5 }) }))) })), _jsx(IconButton, Object.assign({ disabled: isEditLoading, edge: "end", onClick: () => setShowUserFilters(true), size: "large" }, { children: _jsx(FilterIcon, { color: !isEditLoading
                                                                    ? "action"
                                                                    : "disabled" }) }))] }))) }) }))) }) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsxs(FormControl, Object.assign({ required: true, fullWidth: true }, { children: [_jsx(InputLabel, { children: t("users.role") }), _jsxs(Select, Object.assign({ label: t("users.role") + " *", value: role, onChange: event => setRole(event.target.value) }, { children: [_jsx(MenuItem, Object.assign({ value: "admin" }, { children: t("users.roles.admin") })), _jsx(MenuItem, Object.assign({ value: "manager" }, { children: t("users.roles.manager") })), _jsx(MenuItem, Object.assign({ value: "agent" }, { children: t("users.roles.agent") }))] }))] })) }))] })) }), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ color: "inherit", onClick: () => {
                                    setIsEditOpened(false);
                                } }, { children: t("cancel") })), isManage && (_jsx(Button, Object.assign({ style: {
                                    color: "rgb(244, 67, 54)",
                                }, onClick: () => {
                                    setIsDeleteOpened(true);
                                } }, { children: t("users.user-clients.remove-user") }))), _jsx(Button, Object.assign({ disabled: !user || !role, onClick: () => {
                                    isManage ? updateClientUser() : newClientUser();
                                    setIsEditOpened(false);
                                } }, { children: isManage
                                    ? t("clients.client-users.update-user")
                                    : t("clients.client-users.add-user") }))] })] })), _jsxs(Dialog, Object.assign({ open: isDeleteOpened, onClose: () => {
                    setIsDeleteOpened(false);
                } }, { children: [_jsx(DialogTitle, { children: t("delete-component.confirm") }), _jsx(DialogContent, { children: _jsx(DialogContentText, { children: t("clients.client-users.remove-confirm") }) }), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ color: "inherit", onClick: () => {
                                    setIsDeleteOpened(false);
                                } }, { children: t("cancel") })), _jsx(Button, Object.assign({ style: {
                                    color: "rgb(244, 67, 54)",
                                }, onClick: () => {
                                    deleteClientUser();
                                    setIsDeleteOpened(false);
                                    setIsEditOpened(false);
                                } }, { children: t("clients.client-users.remove") }))] })] })), _jsxs(Dialog, Object.assign({ fullWidth: true, maxWidth: "xs", open: showUserFilters, onClose: () => {
                    setShowUserFilters(false);
                } }, { children: [_jsx(DialogTitle, { children: t("filters.search-users-by") }), _jsx(DialogContent, { children: _jsx(InputRadio, { required: false, type: "text", direction: "vertical", options: [
                                {
                                    value: "full_name",
                                    label: t("users.user-attributes.full-name"),
                                },
                                {
                                    value: "email",
                                    label: t("users.user-attributes.email"),
                                },
                                {
                                    value: "id",
                                    label: "ID",
                                },
                            ], value: searchUsersBy ? searchUsersBy : "full_name", setValue: setSearchUsersBy }) }), _jsx(DialogActions, { children: _jsx(Button, Object.assign({ onClick: () => {
                                setShowUserFilters(false);
                            } }, { children: t("close") })) })] }))] }));
}
