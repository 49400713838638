import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import UserIcon from "@mui/icons-material/Group";
import GroupIcon from "@mui/icons-material/BubbleChart";
import BlacklistIcon from "@mui/icons-material/PhoneDisabled";
import OptionIcon from "@mui/icons-material/PlaylistAddCheck";
import FieldIcon from "@mui/icons-material/Input";
import LogIcon from "@mui/icons-material/HistoryToggleOff";
import NotificationsIcon from "@mui/icons-material/NotificationsNone";
import ConditionsIcon from "@mui/icons-material/MoveDown";
import Intro from "../../elements/blocks/intro";
import { useRoles } from "../../../services/roles";
import { useTenancy } from "../../../services/tenancy";
export default function Settings() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const roles = useRoles();
    const tenancy = useTenancy();
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("settings"), breadcrumbs: [
                    {
                        name: t("settings"),
                        link: "/settings",
                    },
                ] }), _jsx(Fragment, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4, lg: 3 }, { children: _jsx(Card, { children: _jsx(CardActionArea, Object.assign({ onClick: () => {
                                        navigate(tenancy.link("/settings/users"));
                                    } }, { children: _jsx(CardContent, { children: _jsxs(Box, Object.assign({ sx: {
                                                height: "120px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                flexDirection: "column",
                                            } }, { children: [_jsx(UserIcon, { style: {
                                                        fontSize: 50,
                                                        marginBottom: "8px",
                                                    } }), _jsx(Typography, Object.assign({ variant: "h5" }, { children: t("users.users") }))] })) }) })) }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4, lg: 3 }, { children: _jsx(Card, { children: _jsx(CardActionArea, Object.assign({ onClick: () => {
                                        navigate(tenancy.link("/settings/groups"));
                                    } }, { children: _jsx(CardContent, { children: _jsxs(Box, Object.assign({ sx: {
                                                height: "120px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                flexDirection: "column",
                                            } }, { children: [_jsx(GroupIcon, { style: {
                                                        fontSize: 50,
                                                        marginBottom: "8px",
                                                    } }), _jsx(Typography, Object.assign({ variant: "h5" }, { children: t("groups.groups") }))] })) }) })) }) })), roles.isAdmin && (_jsxs(Fragment, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Divider, {}) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4, lg: 3 }, { children: _jsx(Card, { children: _jsx(CardActionArea, Object.assign({ onClick: () => {
                                                navigate(tenancy.link("/settings/notifications"));
                                            } }, { children: _jsx(CardContent, { children: _jsxs(Box, Object.assign({ sx: {
                                                        height: "120px",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        flexDirection: "column",
                                                    } }, { children: [_jsx(NotificationsIcon, { style: {
                                                                fontSize: 50,
                                                                marginBottom: "8px",
                                                            } }), _jsx(Typography, Object.assign({ variant: "h5" }, { children: t("notifications.notifications") }))] })) }) })) }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4, lg: 3 }, { children: _jsx(Card, { children: _jsx(CardActionArea, Object.assign({ onClick: () => {
                                                navigate(tenancy.link("/settings/conditions"));
                                            } }, { children: _jsx(CardContent, { children: _jsxs(Box, Object.assign({ sx: {
                                                        height: "120px",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        flexDirection: "column",
                                                    } }, { children: [_jsx(ConditionsIcon, { style: {
                                                                fontSize: 50,
                                                                marginBottom: "8px",
                                                            } }), _jsx(Typography, Object.assign({ variant: "h5" }, { children: t("conditions.conditions") }))] })) }) })) }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4, lg: 3 }, { children: _jsx(Card, { children: _jsx(CardActionArea, Object.assign({ onClick: () => {
                                                navigate(tenancy.link("/settings/fields"));
                                            } }, { children: _jsx(CardContent, { children: _jsxs(Box, Object.assign({ sx: {
                                                        height: "120px",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        flexDirection: "column",
                                                    } }, { children: [_jsx(FieldIcon, { style: {
                                                                fontSize: 50,
                                                                marginBottom: "8px",
                                                            } }), _jsx(Typography, Object.assign({ variant: "h5" }, { children: t("fields.fields") }))] })) }) })) }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4, lg: 3 }, { children: _jsx(Card, { children: _jsx(CardActionArea, Object.assign({ onClick: () => {
                                                navigate(tenancy.link("/settings/options"));
                                            } }, { children: _jsx(CardContent, { children: _jsxs(Box, Object.assign({ sx: {
                                                        height: "120px",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        flexDirection: "column",
                                                    } }, { children: [_jsx(OptionIcon, { style: {
                                                                fontSize: 50,
                                                                marginBottom: "8px",
                                                            } }), _jsx(Typography, Object.assign({ variant: "h5" }, { children: t("options.options") }))] })) }) })) }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4, lg: 3 }, { children: _jsx(Card, { children: _jsx(CardActionArea, Object.assign({ onClick: () => {
                                                navigate(tenancy.link("/settings/blacklist"));
                                            } }, { children: _jsx(CardContent, { children: _jsxs(Box, Object.assign({ sx: {
                                                        height: "120px",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        flexDirection: "column",
                                                    } }, { children: [_jsx(BlacklistIcon, { style: {
                                                                fontSize: 50,
                                                                marginBottom: "8px",
                                                            } }), _jsx(Typography, Object.assign({ variant: "h5" }, { children: t("blacklist.blacklist") }))] })) }) })) }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4, lg: 3 }, { children: _jsx(Card, { children: _jsx(CardActionArea, Object.assign({ onClick: () => {
                                                navigate(tenancy.link("/settings/logs"));
                                            } }, { children: _jsx(CardContent, { children: _jsxs(Box, Object.assign({ sx: {
                                                        height: "120px",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        flexDirection: "column",
                                                    } }, { children: [_jsx(LogIcon, { style: {
                                                                fontSize: 50,
                                                                marginBottom: "8px",
                                                            } }), _jsx(Typography, Object.assign({ variant: "h5" }, { children: t("logs.logs") }))] })) }) })) }) }))] }))] })) })] }));
}
