import axios from "axios";
import { usePrefs } from "../services/prefs";
export const useApiCentral = () => {
    const prefs = usePrefs();
    const api = axios.create({
        baseURL: process.env.API_URL + "/api/central",
        withCredentials: true,
    });
    const source = axios.CancelToken.source();
    const cancel = () => source.cancel();
    const isCancel = error => axios.isCancel(error);
    api.interceptors.response.use(response => response, error => {
        if (error.response &&
            (error.response.status === 401 || error.response.status === 403) &&
            !(window.location.pathname === "/login" ||
                window.location.pathname === "/forgot-password" ||
                window.location.pathname.startsWith("/reset-password") ||
                window.location.pathname.startsWith("/survey") ||
                window.location.pathname.startsWith("/anketa") ||
                window.location.pathname.startsWith("/activity") ||
                window.location.pathname.startsWith("/aktivnost")))
            window.location.replace("/login");
        throw error;
    });
    // Clients
    const GET_clients = (page_size, page, order_by, order_direction, search) => {
        return api.get("/tenants", {
            params: {
                page_size,
                page,
                order_by,
                order_direction,
                search,
            },
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    const CREATE_client = (name, domain, logo, logo_dark, active, seed_test_data) => {
        let formData = new FormData();
        formData.append("name", name);
        formData.append("domain", domain);
        formData.append("active", JSON.stringify(active));
        formData.append("seed_test_data", JSON.stringify(seed_test_data));
        if (logo)
            formData.append("logo", logo);
        if (logo_dark)
            formData.append("logo_dark", logo_dark);
        return api.post("/tenants", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept-Language": prefs.lang,
            },
            cancelToken: source.token,
        });
    };
    const GET_client = (id) => {
        return api.get("/tenants/" + id, {
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    const UPDATE_client = (id, name, domain, logo, logo_dark, active) => {
        let formData = new FormData();
        formData.append("name", name);
        formData.append("domain", domain);
        formData.append("active", JSON.stringify(active));
        if (logo !== undefined)
            formData.append("logo", logo !== null ? logo : "");
        if (logo_dark !== undefined)
            formData.append("logo_dark", logo_dark !== null ? logo_dark : "");
        return api.post("/tenants/" + id, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept-Language": prefs.lang,
            },
            cancelToken: source.token,
        });
    };
    const DELETE_client = (id) => {
        return api.delete("/tenants/" + id, {
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    const GET_currentUserClients = (page_size, page, active) => {
        return api.get("/tenants/current", {
            params: {
                page_size,
                page,
                active,
            },
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    // Users
    const GET_users = (page_size, page, order_by, order_direction, search, search_by) => {
        return api.get("/users", {
            params: {
                page_size,
                page,
                order_by,
                order_direction,
                search,
                search_by,
            },
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    const CREATE_user = (first_name, last_name, email, is_enabled, role, password, phone, avatar) => {
        let formData = new FormData();
        formData.append("first_name", first_name);
        formData.append("last_name", last_name);
        formData.append("email", email);
        formData.append("is_enabled", is_enabled ? "1" : "0");
        formData.append("role", role);
        if (password)
            formData.append("password", password);
        if (phone)
            formData.append("phone", phone);
        if (avatar)
            formData.append("avatar", avatar);
        return api.post("/users", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept-Language": prefs.lang,
            },
            cancelToken: source.token,
        });
    };
    const GET_user = (id) => {
        return api.get("/users/" + id, {
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    const UPDATE_user = (id, first_name, last_name, email, is_enabled, role, old_password, password, phone, avatar, custom_fields, global_id, tenant_id, disable_2fa) => {
        let formData = new FormData();
        formData.append("first_name", first_name);
        formData.append("last_name", last_name);
        formData.append("email", email);
        formData.append("is_enabled", is_enabled ? "1" : "0");
        formData.append("phone", phone ? phone : "");
        formData.append("avatar", avatar ? avatar : "");
        if (role)
            formData.append("role", role);
        if (old_password)
            formData.append("old_password", old_password);
        if (password)
            formData.append("password", password);
        if (disable_2fa)
            formData.append("disable_2fa", disable_2fa.toString());
        if (global_id) {
            return api.post("/tenants/" + tenant_id + "/users/" + global_id, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Accept-Language": prefs.lang,
                },
                cancelToken: source.token,
            });
        }
        else {
            return api.post("/users/" + id, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Accept-Language": prefs.lang,
                },
                cancelToken: source.token,
            });
        }
    };
    const DELETE_user = (id) => {
        return api.delete("/users/" + id, {
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    const GET_currentUser = () => api.get("/users/current", {
        cancelToken: source.token,
        headers: {
            "Accept-Language": prefs.lang,
        },
    });
    const GET_userClients = (id, page_size, page, order_by, order_direction, search) => {
        return api.get("/tenants/user/" + id, {
            params: {
                page_size,
                page,
                order_by,
                order_direction,
                search,
            },
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    const CREATE_userOnClient = (clientId, userId, role) => {
        return api.post("/tenants/" + clientId + "/attach-user/" + userId, {
            role,
        }, {
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    const DELETE_userFromClient = (clientId, globalId) => {
        return api.post("/tenants/" + clientId + "/detach-user/" + globalId, {}, {
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    const UPDATE_userOptions = (lang, dark_mode) => {
        return api.post("/users/current/options", {
            lang,
            dark_mode,
        }, {
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    // Groups
    const GET_groups = (order_by, order_direction) => {
        return api.get("/groups", {
            params: {
                order_by,
                order_direction,
            },
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    const CREATE_group = (name, parent_group_id) => {
        return api.post("/groups", {
            name,
            parent_group_id,
        }, {
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    const GET_group = (id) => {
        return api.get("/groups/" + id, {
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    const UPDATE_group = (id, name, parent_group_id) => {
        return api.post("/groups/" + id, {
            id,
            name,
            parent_group_id,
        }, {
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    const DELETE_group = (id) => {
        return api.delete("/groups/" + id, {
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    const GET_groupUsers = (id, page_size, page, order_by, order_direction, search) => {
        return api.get("/groups/" + id + "/users", {
            params: {
                page_size,
                page,
                order_by,
                order_direction,
                search,
            },
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    const GET_groupAttachableUsers = (id) => {
        return api.get("/groups/" + id + "/attachable-users", {
            params: {
                page_size: 10000,
                page: 1,
            },
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    const CREATE_userOnGroup = (groupId, userId) => {
        return api.post("/groups/" + groupId + "/attach-user/" + userId, {}, {
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    const DELETE_userFromGroup = (groupId, userId) => {
        return api.post("/groups/" + groupId + "/detach-user/" + userId, {}, {
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    // Queues
    const GET_queue = (queue) => {
        return api.get("/queues", {
            params: {
                queue,
            },
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    // Activity Logs
    const GET_logs = (page_size, page, order_by, order_direction, search, filter_causer_id, filter_subject_type) => {
        return api.get("/activity-logs", {
            params: {
                page_size,
                page,
                order_by,
                order_direction,
                search,
                filter_causer_id,
                filter_subject_type,
            },
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    // Blacklist
    const GET_blacklistedNumbers = (page_size, page, order_by, order_direction, search) => {
        return api.get("/blacklist-numbers", {
            params: {
                page_size,
                page,
                order_by,
                order_direction,
                search,
            },
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    const CREATE_blacklistedNumber = (phone_area_id, number) => {
        return api.post("/blacklist-numbers", { phone_area_id, number }, {
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    const DELETE_blacklistedNumber = (id) => {
        return api.delete("/blacklist-numbers/" + id, {
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    const CHECK_blacklistedNumbers = (phone_area_id, number) => {
        return api.post("/blacklist-numbers/check", {
            phone_area_id,
            number,
        }, {
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    const IMPORT_blacklistedNumbers = (file) => {
        let formData = new FormData();
        formData.append("file", file);
        return api.post("/blacklist-numbers/import", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept-Language": prefs.lang,
            },
            cancelToken: source.token,
        });
    };
    // Phone Countries
    const GET_phoneCountries = (page_size, page, order_by, order_direction, search) => {
        return api.get("/phone-countries", {
            params: {
                page_size,
                page,
                order_by,
                order_direction,
                search,
            },
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    // Phone Areas
    const GET_phoneAreas = (page_size, page, order_by, order_direction, search) => {
        return api.get("/phone-areas", {
            params: {
                page_size,
                page,
                order_by,
                order_direction,
                search,
            },
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    const GET_notifications = (page_size, page, order_direction, filter_read, include_unread_count) => {
        return api.get("/notifications", {
            params: {
                page_size,
                page,
                order_direction,
                filter_read,
                include_unread_count,
            },
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    const MARK_notificationAsRead = (notification_id, read_all) => {
        return api.post("/notifications/read", {
            notification_id,
            read_all,
        }, {
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    return {
        cancel,
        isCancel,
        // Clients
        GET_clients,
        CREATE_client,
        GET_client,
        UPDATE_client,
        DELETE_client,
        GET_currentUserClients,
        // Users
        GET_users,
        CREATE_user,
        GET_user,
        UPDATE_user,
        DELETE_user,
        GET_currentUser,
        UPDATE_userOptions,
        // Groups
        GET_groups,
        CREATE_group,
        GET_group,
        UPDATE_group,
        DELETE_group,
        // Users <> Clients
        GET_userClients,
        CREATE_userOnClient,
        DELETE_userFromClient,
        // Users <> Groups
        GET_groupUsers,
        GET_groupAttachableUsers,
        CREATE_userOnGroup,
        DELETE_userFromGroup,
        // Queues
        GET_queue,
        // Logs
        GET_logs,
        // Blacklist
        GET_blacklistedNumbers,
        CREATE_blacklistedNumber,
        DELETE_blacklistedNumber,
        CHECK_blacklistedNumbers,
        IMPORT_blacklistedNumbers,
        // Phone countries
        GET_phoneCountries,
        // Phone areas
        GET_phoneAreas,
        // Notifications
        GET_notifications,
        MARK_notificationAsRead,
    };
};
