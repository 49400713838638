import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import InputText from "../inputs/input-text";
import InputCustomFields from "../inputs/input-custom-fields";
import GroupDelete from "./group-delete";
import { useAlert } from "../../../services/alert";
import { useRoles } from "../../../services/roles";
import { useApiCentral } from "../../../api/api-central";
import { useApiTenant } from "../../../api/api-tenant";
export default function GroupForm({ type, status, group, setRefreshGroups }) {
    var _a;
    const { t } = useTranslation();
    const alert = useAlert();
    const roles = useRoles();
    const apiCentral = useApiCentral();
    const apiTenant = useApiTenant();
    const [name, setName] = useState("");
    const [customFields, setCustomFields] = useState((_a = group === null || group === void 0 ? void 0 : group.custom_fields) !== null && _a !== void 0 ? _a : []);
    const [isSaving, setIsSaving] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errors, setErrors] = useState(null);
    const [isLoadingCustomFields, setIsLoadingCustomFields] = useState(true);
    const [tenancyCustomFields, setTenancyCustomFields] = useState([]);
    const [disabledCustomFields, setDisabledCustomFields] = useState([]);
    useEffect(() => {
        apiTenant
            .GET_customFields("tenant_groups", 10000, 1, "index")
            .then(response => {
            setTenancyCustomFields(response.data.custom_fields);
            setIsLoadingCustomFields(false);
        })
            .catch(error => {
            if (!apiTenant.isCancel(error)) {
                console.error(error);
                setIsLoadingCustomFields(false);
            }
        });
        return () => apiTenant.cancel();
    }, []);
    useEffect(() => {
        var _a, _b;
        if (status === "new") {
            setName("");
            setCustomFields([]);
            setErrors(null);
        }
        else {
            setName((_a = group === null || group === void 0 ? void 0 : group.name) !== null && _a !== void 0 ? _a : "");
            setCustomFields((_b = group === null || group === void 0 ? void 0 : group.custom_fields) !== null && _b !== void 0 ? _b : []);
        }
    }, [status, group]);
    const createGroup = () => {
        setIsSaving(true);
        setIsError(false);
        if (type === "client") {
            apiTenant
                .CREATE_group(name, customFields.sort((a, b) => a.index - b.index), group === null || group === void 0 ? void 0 : group.id)
                .then(() => {
                setIsSaving(false);
                setRefreshGroups(true);
                alert.show(t("groups.group-form.group-created"), "success");
            })
                .catch(error => {
                setIsSaving(false);
                setIsError(true);
                setErrors(error.response.data.errors);
                console.error(error.response);
                alert.show(t("groups.group-form.creating-error"), "error");
            });
        }
        else {
            apiCentral
                .CREATE_group(name, group === null || group === void 0 ? void 0 : group.id)
                .then(() => {
                setIsSaving(false);
                setRefreshGroups(true);
                alert.show(t("groups.group-form.group-created"), "success");
            })
                .catch(error => {
                setIsSaving(false);
                setIsError(true);
                setErrors(error.response.data.errors);
                console.error(error.response);
                alert.show(t("groups.group-form.creating-error"), "error");
            });
        }
    };
    const updateGroup = () => {
        if (!group)
            return;
        setIsSaving(true);
        setIsError(false);
        if (type === "client") {
            apiTenant
                .UPDATE_group(group.id, name, customFields.sort((a, b) => (a.custom_field ? a.custom_field.index : a.index) -
                (b.custom_field ? b.custom_field.index : b.index)))
                .then(() => {
                setIsSaving(false);
                setRefreshGroups(true);
                alert.show(t("groups.group-form.group-updated"), "success");
            })
                .catch(error => {
                setIsSaving(false);
                setIsError(true);
                setErrors(error.response.data.errors);
                console.error(error.response);
                alert.show(t("groups.group-form.updating-error"), "error");
            });
        }
        else {
            apiCentral
                .UPDATE_group(group.id, name)
                .then(() => {
                setIsSaving(false);
                setRefreshGroups(true);
                alert.show(t("groups.group-form.group-updated"), "success");
            })
                .catch(error => {
                setIsSaving(false);
                setIsError(true);
                setErrors(error.response.data.errors);
                console.error(error.response);
                alert.show(t("groups.group-form.updating-error"), "error");
            });
        }
    };
    return (_jsxs(Fragment, { children: [(roles.isAdmin || roles.isSuperAdmin) && (_jsx(Box, Object.assign({ component: "form", sx: {
                    marginBottom: 4,
                } }, { children: _jsx(Paper, { children: _jsxs(Box, Object.assign({ sx: {
                            padding: 4,
                        } }, { children: [status === "new" && (_jsx(Box, Object.assign({ sx: {
                                    paddingBottom: 4,
                                } }, { children: _jsxs(Typography, Object.assign({ variant: "h6" }, { children: [_jsx(Box, Object.assign({ component: "span", sx: {
                                                paddingRight: 0.5,
                                            } }, { children: group
                                                ? t("groups.new-group-at")
                                                : t("groups.new-group") })), _jsx(Box, Object.assign({ component: "strong" }, { children: group ? group === null || group === void 0 ? void 0 : group.name : "" }))] })) }))), status === "edit" && (_jsx(Box, Object.assign({ sx: {
                                    paddingBottom: 4,
                                } }, { children: _jsx(Typography, Object.assign({ variant: "h6" }, { children: t("groups.edit-group") })) }))), _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(InputText, { label: t("groups.group-name") + " *", required: true, value: name, setValue: setName, info: null, pattern: null, error: "name", errors: errors, isError: isError, setIsError: setIsError }) })), type === "client" &&
                                        (isLoadingCustomFields ? (_jsx(Fragment, {})) : (tenancyCustomFields.length > 0 && (_jsxs(Fragment, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Divider, { light: true }) })), _jsx(Grid, Object.assign({ container: true, spacing: 4 }, { children: _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(InputCustomFields, { type: "groups", tenancyCustomFields: tenancyCustomFields, customFields: customFields, setCustomFields: setCustomFields, disabledCustomFields: disabledCustomFields, setDisabledCustomFields: setDisabledCustomFields, error: "custom_fields.", errors: errors, isError: isError, setIsError: setIsError }) })) }))] })))), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Divider, { light: true }) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Box, Object.assign({ sx: {
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                            } }, { children: _jsx(LoadingButton, Object.assign({ type: "submit", variant: "contained", color: "secondary", loading: isSaving, disabled: !name, onClick: () => {
                                                    status === "new" ? createGroup() : updateGroup();
                                                }, loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: status === "new"
                                                    ? t("form-component.create")
                                                    : t("form-component.update") })) })) }))] }))] })) }) }))), status === "edit" && group && (_jsx(Fragment, { children: (roles.isAdmin || roles.isSuperAdmin) && (_jsx(GroupDelete, { type: type, group: group, setRefreshGroups: setRefreshGroups })) }))] }));
}
