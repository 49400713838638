import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import FormHelperText from "@mui/material/FormHelperText";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import Alert from "@mui/material/Alert";
import ButtonGroup from "@mui/material/ButtonGroup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { usePrefs } from "../../../services/prefs";
import { useAuth } from "../../../services/auth";
import { useRoles } from "../../../services/roles";
import { useTenancy } from "../../../services/tenancy";
import { useApiPublic } from "../../../api/api-public";
import { useApiAuth } from "../../../api/api-auth";
import { colors } from "../../../helpers/colors";
import { GlobalStyles } from "@mui/material";
const logoDark = require("../../../assets/" + process.env.APP_ID + "/logo-dark.svg");
const useStyles = makeStyles(() => createStyles({
    copy: {
        color: "#fff",
    },
}));
export default function LogIn() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const classes = useStyles();
    const prefs = usePrefs();
    const auth = useAuth();
    const tenancy = useTenancy();
    const apiPublic = useApiPublic();
    const apiAuth = useApiAuth();
    const roles = useRoles();
    const [client, setClient] = useState(null);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [token, setToken] = useState("");
    const [is2faError, setIs2faError] = useState(false);
    const [is2faRecovery, setIs2faRecovery] = useState(false);
    const [is2faLoading, set2faIsLoading] = useState(false);
    const [qrCode, setQrCode] = useState(null);
    const [authenticationCode, setAuthenticationCode] = useState("");
    const TOKEN_AUTH = 6;
    const TOKEN_BACKUP = 21;
    const handleKeyboard = event => {
        auth.setIsError(false);
        setIs2faError(false);
        if (event.key !== "Enter")
            return;
        if (email && password)
            auth.logIn(email, password);
        if (token.length === (is2faRecovery ? TOKEN_BACKUP : TOKEN_AUTH))
            handle2faConfirm();
    };
    const handle2faConfirm = () => {
        set2faIsLoading(true);
        apiAuth
            .CREATE_check2Fa(is2faRecovery ? undefined : token, is2faRecovery ? token : undefined)
            .then(() => {
            auth.currentUser(true);
        })
            .catch(error => {
            console.error(error.response);
            setIs2faError(true);
            set2faIsLoading(false);
        });
    };
    const handle2faEnforcedConfirm = () => {
        set2faIsLoading(true);
        apiAuth
            .CREATE_confirm2fa(authenticationCode)
            .then(() => {
            auth.currentUser(true);
        })
            .catch(error => {
            console.error(error.response);
            set2faIsLoading(false);
        });
    };
    const handle2faEnforced = () => {
        if (!email || !password)
            return;
        set2faIsLoading(true);
        apiAuth
            .POST_2faConfirmPassword(email, password)
            .then(() => {
            apiAuth
                .CREATE_enable2fa()
                .then(() => {
                apiAuth
                    .GET_2faQrCode()
                    .then(response => {
                    set2faIsLoading(false);
                    setQrCode(response.data.svg);
                    setTimeout(() => {
                        var _a;
                        (_a = document.getElementById("focus")) === null || _a === void 0 ? void 0 : _a.focus();
                    }, 300);
                })
                    .catch(error => {
                    set2faIsLoading(false);
                    console.error(error.response);
                });
            })
                .catch(error => {
                set2faIsLoading(false);
                console.error(error.response);
            });
        })
            .catch(error => {
            set2faIsLoading(false);
            console.error(error.response);
        });
    };
    useEffect(() => {
        if (!tenancy.isSubDomain)
            return;
        setIsLoading(true);
        apiPublic
            .GET_clientByDomain(tenancy.subDomain)
            .then(response => {
            setClient(response.data.tenant);
            setIsLoading(false);
        })
            .catch(error => {
            if (!apiPublic.isCancel(error)) {
                // @ts-ignore
                window.location.replace(process.env.APP_URL);
            }
        });
        return () => apiPublic.cancel();
    }, []);
    useEffect(() => {
        if (auth.user) {
            navigate("/");
        }
        else {
            roles.setIsSuperAdmin(false);
            roles.setIsAdmin(false);
            roles.setIsManager(false);
            roles.setIsAgent(false);
            roles.setRoleTenant(null);
            roles.setGroupsTenant(null);
            roles.setUserTenant(null);
            tenancy.setClient(null);
        }
    }, [auth.user]);
    useEffect(() => {
        if (!auth.is2faEnforced)
            return;
        handle2faEnforced();
    }, [auth.is2faEnforced]);
    useEffect(() => {
        if (!auth.is2faNeeded)
            return;
        setTimeout(() => {
            var _a;
            (_a = document.getElementById("focus")) === null || _a === void 0 ? void 0 : _a.focus();
        }, 300);
    }, [auth.is2faNeeded]);
    return isLoading ? (_jsx(Backdrop, Object.assign({ invisible: true, open: true }, { children: _jsx(CircularProgress, { color: "inherit" }) }))) : (_jsxs(Box, Object.assign({ sx: {
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
        } }, { children: [_jsx(GlobalStyles, { styles: {
                    body: {
                        backgroundColor: process.env.APP_ID === "mcd"
                            ? colors.primary.light
                            : colors.primary.dark,
                    },
                } }), _jsx(Box, Object.assign({ sx: {
                    position: "absolute",
                    top: 15,
                    right: 10,
                    backgroundColor: "#fff",
                } }, { children: _jsxs(ButtonGroup, Object.assign({ size: "small", variant: "text", color: "inherit" }, { children: [_jsx(Button, Object.assign({ disabled: prefs.lang === "en", onClick: () => {
                                i18n.changeLanguage("en").then(() => {
                                    prefs.setLang("en");
                                });
                            } }, { children: "EN" })), _jsx(Button, Object.assign({ disabled: prefs.lang === "hr", onClick: () => {
                                i18n.changeLanguage("hr").then(() => {
                                    prefs.setLang("hr");
                                });
                            } }, { children: "HR" }))] })) })), _jsxs(Container, Object.assign({ maxWidth: "xs" }, { children: [_jsx(Box, Object.assign({ sx: {
                            display: "flex",
                            justifyContent: "center",
                            paddingTop: 4,
                            paddingBottom: 2,
                        } }, { children: client && client.logo_dark_url ? (_jsx("img", { src: client.logo_dark_url, style: {
                                maxHeight: "120px",
                                maxWidth: "220px",
                                objectFit: "contain",
                            }, alt: "Logo" })) : (_jsx("img", { src: logoDark, style: {
                                maxHeight: "120px",
                                maxWidth: "220px",
                                objectFit: "contain",
                            }, alt: "Logo" })) })), _jsx(Box, Object.assign({ sx: {
                            paddingY: 4,
                        } }, { children: _jsx(Paper, Object.assign({ onKeyPress: handleKeyboard }, { children: _jsx(Box, Object.assign({ sx: {
                                    padding: 4,
                                } }, { children: auth.is2faNeeded ? (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                                                padding: 2,
                                            } }, { children: _jsxs(FormControl, Object.assign({ required: true, fullWidth: true, error: is2faError }, { children: [_jsx(InputLabel, { children: is2faRecovery
                                                            ? t("2fa.recovery-token")
                                                            : t("2fa.token") }), _jsx(OutlinedInput, { autoFocus: true, label: is2faRecovery
                                                            ? t("2fa.recovery-token")
                                                            : t("2fa.token"), value: token, onChange: event => setToken(event.target.value), id: "focus" }), _jsx(FormHelperText, { children: is2faRecovery
                                                            ? t("2fa.backup-token")
                                                            : t("2fa.app-token") })] })) })), _jsx(Box, Object.assign({ sx: {
                                                padding: 2,
                                            } }, { children: _jsx(LoadingButton, Object.assign({ fullWidth: true, size: "large", variant: "contained", color: "secondary", type: "button", loading: is2faLoading, disabled: token.length !==
                                                    (is2faRecovery ? TOKEN_BACKUP : TOKEN_AUTH), onClick: handle2faConfirm }, { children: t("confirm") })) })), _jsx(Box, Object.assign({ sx: {
                                                display: "flex",
                                                justifyContent: "center",
                                                paddingTop: 1,
                                            } }, { children: _jsx(Button, Object.assign({ type: "button", size: "small", color: "inherit", onClick: () => setIs2faRecovery(!is2faRecovery) }, { children: is2faRecovery
                                                    ? t("2fa.authorization-code")
                                                    : t("2fa.recovery-code") })) }))] })) : auth.is2faEnforced ? (_jsxs(Fragment, { children: [_jsx(Alert, Object.assign({ severity: "info" }, { children: t("2fa.finish") })), _jsx(Typography, Object.assign({ variant: "body2", color: "textSecondary", sx: {
                                                marginY: 2,
                                            } }, { children: t("2fa.scan") })), _jsx(Box, Object.assign({ sx: {
                                                display: "flex",
                                                justifyContent: "center",
                                                marginBottom: 2,
                                            } }, { children: qrCode ? (_jsx("div", { dangerouslySetInnerHTML: {
                                                    __html: qrCode,
                                                } })) : (_jsx(Skeleton, { width: "192px", height: "192px" })) })), _jsxs(FormControl, Object.assign({ required: true, fullWidth: true, error: is2faError }, { children: [_jsx(InputLabel, { children: t("2fa.token") }), _jsx(OutlinedInput, { autoFocus: true, label: t("2fa.token"), value: authenticationCode, onChange: event => setAuthenticationCode(event.target.value), id: "focus" })] })), _jsx(Box, Object.assign({ sx: {
                                                paddingTop: 2,
                                            } }, { children: _jsx(LoadingButton, Object.assign({ fullWidth: true, size: "large", variant: "contained", color: "secondary", type: "button", disabled: authenticationCode.length !== 6, loading: isLoading, onClick: handle2faEnforcedConfirm, loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: t("confirm") })) }))] })) : (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                                                padding: 2,
                                            } }, { children: _jsxs(FormControl, Object.assign({ required: true, fullWidth: true, error: auth.isError }, { children: [_jsx(InputLabel, { children: t("pages.auth.email") }), _jsx(OutlinedInput, { autoFocus: true, type: "email", label: t("pages.auth.email"), value: email, onChange: event => setEmail(event.target.value) })] })) })), _jsx(Box, Object.assign({ sx: {
                                                padding: 2,
                                            } }, { children: _jsxs(FormControl, Object.assign({ required: true, fullWidth: true, error: auth.isError }, { children: [_jsx(InputLabel, { children: t("pages.auth.login.password") }), _jsx(OutlinedInput, { type: showPassword ? "text" : "password", label: t("pages.auth.login.password"), value: password, onChange: event => setPassword(event.target.value), endAdornment: _jsx(InputAdornment, Object.assign({ position: "end" }, { children: _jsx(IconButton, Object.assign({ onClick: () => setShowPassword(!showPassword), edge: "end", size: "large" }, { children: showPassword ? (_jsx(VisibilityIcon, {})) : (_jsx(VisibilityOffIcon, {})) })) })) })] })) })), _jsx(Box, Object.assign({ sx: {
                                                paddingX: 2,
                                                paddingTop: 2,
                                                paddingBottom: 1,
                                            } }, { children: _jsx(LoadingButton, Object.assign({ fullWidth: true, size: "large", variant: "contained", color: "secondary", type: "button", loading: auth.isLogginInOut, disabled: auth.isLogginInOut || !email || !password, onClick: () => {
                                                    if (email && password)
                                                        auth.logIn(email, password);
                                                } }, { children: t("pages.auth.login.login") })) })), _jsx(Box, Object.assign({ sx: {
                                                display: "flex",
                                                justifyContent: "center",
                                                paddingTop: 1,
                                            } }, { children: _jsx(Button, Object.assign({ type: "button", size: "small", color: "inherit", onClick: () => {
                                                    navigate("/forgot-password");
                                                } }, { children: t("pages.auth.login.forgot-password") })) }))] })) })) })) })), _jsx(Box, Object.assign({ sx: {
                            paddingTop: 2,
                            paddingBottom: 4,
                            textAlign: "center",
                        } }, { children: _jsxs(Typography, Object.assign({ className: classes.copy }, { children: [_jsx(Link, Object.assign({ href: process.env.APP_LINK, color: "inherit", underline: "hover" }, { children: process.env.APP_NAME })), _jsxs(Box, Object.assign({ component: "span", sx: {
                                        paddingLeft: 1,
                                    } }, { children: ["\u00A9 ", new Date().getFullYear(), "."] }))] })) }))] }))] })));
}
