import axios from "axios";
import { usePrefs } from "../services/prefs";
export const useApiAuth = () => {
    const prefs = usePrefs();
    const api = axios.create({
        baseURL: process.env.API_URL,
        withCredentials: true,
    });
    const source = axios.CancelToken.source();
    const cancel = () => source.cancel();
    const isCancel = error => axios.isCancel(error);
    const GET_crsfCookie = () => {
        return api.get("/sanctum/csrf-cookie", {
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    const POST_logIn = (email, password) => {
        return api.post("/api/fortify/login", {
            email,
            password,
        }, {
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    const POST_logOut = () => {
        return api.post("/api/fortify/logout", {}, {
            cancelToken: source.token,
            headers: {
                "Accept-Language": prefs.lang,
            },
        });
    };
    // 2FA
    const GET_2faConfirmedPassword = () => api.get("/api/fortify/user/confirmed-password-status", {
        cancelToken: source.token,
    });
    const POST_2faConfirmPassword = (email, password) => api.post("/api/fortify/user/confirm-password", {
        email,
        password,
    }, {
        cancelToken: source.token,
    });
    const CREATE_enable2fa = () => api.post("/api/fortify/user/two-factor-authentication", null, {
        cancelToken: source.token,
    });
    const DELETE_disable2fa = () => api.delete("/api/fortify/user/two-factor-authentication", {
        cancelToken: source.token,
    });
    const CREATE_check2Fa = (code, recovery_code = undefined) => api.post("/api/fortify/two-factor-challenge", {
        code,
        recovery_code,
    }, {
        cancelToken: source.token,
    });
    const GET_2faQrCode = () => api.get("/api/fortify/user/two-factor-qr-code", {
        cancelToken: source.token,
    });
    const GET_2faRecoveryCodes = () => api.get("/api/fortify/user/two-factor-recovery-codes", {
        cancelToken: source.token,
    });
    const POST_2faRecoveryCodes = () => api.post("/api/fortify/user/two-factor-recovery-codes", null, {
        cancelToken: source.token,
    });
    const CREATE_confirm2fa = (code) => api.post("/api/fortify/user/confirmed-two-factor-authentication", {
        code,
    }, {
        cancelToken: source.token,
    });
    return {
        cancel,
        isCancel,
        // Auth
        GET_crsfCookie,
        POST_logIn,
        POST_logOut,
        // 2FA
        GET_2faConfirmedPassword,
        POST_2faConfirmPassword,
        CREATE_enable2fa,
        DELETE_disable2fa,
        CREATE_check2Fa,
        GET_2faQrCode,
        GET_2faRecoveryCodes,
        POST_2faRecoveryCodes,
        CREATE_confirm2fa,
    };
};
