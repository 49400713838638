import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Intro from "../elements/blocks/intro";
import Controls from "../elements/blocks/controls";
import DashboardMetrics from "../elements/dashboard/dashboard-metrics";
import TicketsTable from "../elements/tickets/tickets-table";
import TicketCampaignSelect from "../elements/tickets/ticket-campaign-select";
import { useAuth } from "../../services/auth";
import { useRoles } from "../../services/roles";
import { useApiTenant } from "../../api/api-tenant";
export default function Dashboard() {
    const { t } = useTranslation();
    const auth = useAuth();
    const roles = useRoles();
    const api = useApiTenant();
    const [newTicket, setNewTicket] = useState(false);
    const [tab, setTab] = useState(roles.isAdmin || roles.isManager ? "tickets" : "assigned");
    const [displayOptions, setDisplayOptions] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const personalizedMessage = () => {
        let hour = new Date().getHours();
        let message = "";
        if (hour > 5 && hour <= 11)
            message += t("dashboard.good-morning");
        else if (hour > 11 && hour <= 17)
            message += t("dashboard.good-afternoon");
        else if (hour > 17 && hour <= 22)
            message += t("dashboard.good-evening");
        else
            message += t("dashboard.good-night");
        if (auth.user)
            message += ", " + auth.user.first_name;
        return message;
    };
    useEffect(() => {
        api.GET_displayOptionsForDashboard()
            .then(response => {
            setDisplayOptions(roles.isAgent
                ? response.data.agent
                : roles.isManager
                    ? response.data.manager
                    : response.data.admin);
            setIsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsLoading(false);
            }
        });
        return () => api.cancel();
    }, []);
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: personalizedMessage(), breadcrumbs: [
                    {
                        name: t("dashboard.dashboard"),
                        link: "/dashboard",
                    },
                ], button: process.env.APP_ID === "mcd" && roles.isAgent
                    ? undefined
                    : {
                        label: t("tickets.new-ticket"),
                        action: () => setNewTicket(true),
                        color: "secondary",
                        type: "new",
                    }, loading: isLoading }), (roles.isManager || roles.isAdmin) && _jsx(DashboardMetrics, {}), isLoading ? (_jsx(Fragment, {})) : (_jsxs(Fragment, { children: [_jsx(Controls, { tabs: roles.isAdmin || roles.isManager
                            ? process.env.APP_ID === "mcd"
                                ? [
                                    {
                                        label: t("dashboard.all-tickets"),
                                        value: "tickets",
                                    },
                                    {
                                        label: t("dashboard.assigned-to-me"),
                                        value: "assigned",
                                    },
                                ]
                                : [
                                    {
                                        label: t("dashboard.all-tickets"),
                                        value: "tickets",
                                    },
                                    {
                                        label: t("dashboard.assigned-to-me"),
                                        value: "assigned",
                                    },
                                    {
                                        label: t("dashboard.my-drafts"),
                                        value: "drafts",
                                    },
                                    {
                                        label: t("dashboard.my-schedule"),
                                        value: "scheduled",
                                    },
                                    {
                                        label: t("dashboard.my-tickets"),
                                        value: "user",
                                    },
                                ]
                            : process.env.APP_ID === "mcd"
                                ? [
                                    {
                                        label: t("dashboard.assigned-to-me"),
                                        value: "assigned",
                                    },
                                ]
                                : [
                                    {
                                        label: t("dashboard.assigned-to-me"),
                                        value: "assigned",
                                    },
                                    {
                                        label: t("dashboard.my-drafts"),
                                        value: "drafts",
                                    },
                                    {
                                        label: t("dashboard.my-schedule"),
                                        value: "scheduled",
                                    },
                                    {
                                        label: t("dashboard.my-tickets"),
                                        value: "user",
                                    },
                                ], tab: tab, setTab: setTab }), tab === "tickets" && (_jsx(TicketsTable, { type: "tickets", displayOptions: displayOptions })), roles.userTenant && (_jsxs(Fragment, { children: [tab === "assigned" && (_jsx(TicketsTable, { type: "assigned", user: roles.userTenant, displayOptions: displayOptions })), tab === "drafts" && (_jsx(TicketsTable, { type: "drafts", user: roles.userTenant, displayOptions: displayOptions })), tab === "scheduled" && (_jsx(TicketsTable, { type: "scheduled", user: roles.userTenant, displayOptions: displayOptions })), tab === "user" && (_jsx(TicketsTable, { type: "user", user: roles.userTenant, displayOptions: displayOptions }))] }))] })), _jsx(TicketCampaignSelect, { newTicket: newTicket, setNewTicket: setNewTicket })] }));
}
