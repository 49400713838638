import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Intro from "../../elements/blocks/intro";
import Nope from "../../elements/blocks/nope";
import { useApiCentral } from "../../../api/api-central";
import { useTenancy } from "../../../services/tenancy";
export default function ThreeCX() {
    const { t } = useTranslation();
    const { callerDisplayName, callerNumber } = useParams();
    const navigate = useNavigate();
    const api = useApiCentral();
    const tenancy = useTenancy();
    const [isLoading, setIsLoading] = useState(true);
    const [isInValid, setIsInValid] = useState(false);
    useEffect(() => {
        const queue = (callerDisplayName === null || callerDisplayName === void 0 ? void 0 : callerDisplayName.includes(":"))
            ? callerDisplayName.split(":").slice(-1)[0]
            : callerDisplayName;
        if (!queue) {
            setIsLoading(false);
            setIsInValid(true);
            return;
        }
        api.GET_queue(queue)
            .then(response => {
            const client = response.data.tenant;
            const campaign = response.data.campaign;
            if (client && campaign) {
                tenancy.setClient(client);
                if (callerNumber)
                    navigate("/" +
                        client.domain +
                        "/campaigns/" +
                        campaign.id +
                        "/new-ticket/" +
                        callerNumber);
                else
                    navigate("/" + client.domain + "/campaigns/" + campaign.id + "/new-ticket");
            }
            else {
                setIsLoading(false);
                setIsInValid(true);
            }
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsLoading(false);
                setIsInValid(true);
            }
        });
        return () => api.cancel();
    }, []);
    return (_jsxs(Fragment, { children: [isLoading && (_jsx(Backdrop, Object.assign({ invisible: true, open: true }, { children: _jsx(CircularProgress, { color: "inherit" }) }))), isInValid && (_jsxs(Fragment, { children: [_jsx(Intro, { name: t("tickets.new-ticket"), breadcrumbs: [
                            {
                                name: "3CX",
                                link: "/3cx",
                            },
                        ] }), _jsx(Nope, { title: t("errors.404"), caption: t("errors.queues.not-found") })] }))] }));
}
