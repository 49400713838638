import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import EditIcon from "@mui/icons-material/Settings";
import { useApiTenant } from "../../../../api/api-tenant";
import { useAlert } from "../../../../services/alert";
export default function PriorityColumn({ type, ticket, ticketPriority, isPreview, refreshTableData, }) {
    const { t } = useTranslation();
    const api = useApiTenant();
    const alert = useAlert();
    const [priorityDialogOpen, setPriorityDialogOpen] = useState(false);
    const [priority, setPriority] = useState();
    const [isSavingTicketDetail, setIsSavingTicketDetail] = useState(false);
    const updateTicketPriority = () => {
        setIsSavingTicketDetail(true);
        api.CREATE_ticketDetail(ticket.id, priority.id)
            .then(() => {
            refreshTableData();
            alert.show(t("tickets.tickets-table.priority-updated"), "success");
        })
            .catch(error => {
            setIsSavingTicketDetail(false);
            if (error.response && error.response.data && error.response.data.errors) {
                console.error(error.response);
            }
            alert.show(t("tickets.tickets-table.updating-priority-error"), "error");
        });
    };
    const resetPriorityDialog = () => {
        setPriorityDialogOpen(false);
        setIsSavingTicketDetail(false);
        setTimeout(() => {
            setPriority(null);
        }, 300);
    };
    return (_jsxs(Fragment, { children: [_jsx(Chip, { label: ticketPriority.name, size: "small", style: {
                    border: "1px solid " +
                        (ticketPriority.level < 33
                            ? "rgb(244, 67, 54, 1)"
                            : ticketPriority.level >= 33 && ticketPriority.level <= 66
                                ? "rgb(255, 152, 0, 1)"
                                : "rgb(33, 150, 243, 1)"),
                    backgroundColor: ticketPriority.level < 33
                        ? "rgb(244, 67, 54, 0.2)"
                        : ticketPriority.level >= 33 && ticketPriority.level <= 66
                            ? "rgb(255, 152, 0, 0.2)"
                            : "rgb(33, 150, 243, 0.2)",
                }, onDelete: type !== "history" && !isPreview
                    ? () => {
                        setPriorityDialogOpen(true);
                        setPriority(ticketPriority);
                    }
                    : undefined, deleteIcon: type !== "history" && !isPreview ? (_jsx(Tooltip, Object.assign({ arrow: true, placement: "top", 
                    // @ts-ignore
                    title: t("tickets.tickets-table.edit-priority") }, { children: _jsx(EditIcon, {}) }))) : undefined }), _jsxs(Dialog, Object.assign({ fullWidth: true, maxWidth: "xs", open: priorityDialogOpen, onClose: resetPriorityDialog }, { children: [_jsx(DialogContent, Object.assign({ sx: {
                            paddingTop: "32px !important",
                        } }, { children: _jsx(Autocomplete, { options: ticket.campaign.ticket_priorities, getOptionLabel: priority => priority.name, isOptionEqualToValue: (option, value) => option.id === value.id, value: priority, onChange: (event, value) => setPriority(value), renderOption: (props, priority) => (_jsx("li", Object.assign({}, props, { children: _jsx(Box, Object.assign({ sx: {
                                        display: "flex",
                                        flexDirection: "column",
                                    } }, { children: _jsx(Box, Object.assign({ sx: {
                                            display: "flex",
                                        } }, { children: _jsx(Typography, { children: priority.name }) })) }), priority.id) }))), renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("tickets.ticket-attributes.priority"), required: true, fullWidth: true, InputProps: Object.assign({}, params.InputProps) }))) }) })), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ color: "inherit", onClick: resetPriorityDialog }, { children: t("cancel") })), _jsx(LoadingButton, Object.assign({ loading: isSavingTicketDetail, loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }), disabled: !priority, onClick: updateTicketPriority }, { children: t("tickets.tickets-table.edit-priority") }))] })] }))] }));
}
